import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { GridRow, GridColumn, LazyImg } from "arv-reactcomponents";
import LazyLoad from 'react-lazyload';
import StoryComponent from "../StoryComponent";
import {
  getBackgroundColorCode,
  isIframe,
  imageFileName,
} from "../../../../utils";

@inject("BrandStore")
@observer
class BrandStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoLoaded: false,
    };
    this.onLogoImageLoad = this.onLogoImageLoad.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->BrandStory", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error brand story ${error} ${errorInfo} ${this}`,
    );
  }

  onLogoImageLoad() {
    this.setState({ logoLoaded: true });
  }
  get modifiedData() {
    const { data } = this.props;
    const {
      storyImages: [image],
      video,
      identity,
    } = data.value;
    return {
      images: [image],
      video,
      storyType: {
        externalURL: "/video",
      },
      identity,
    };
  }
  get logoImage() {
    const { data } = this.props;
    const {
      logoImages: [image],
    } = data.value;
    return (
      (image &&
        image.path &&
        `${this.props.BrandStore.urlPrefix}${image && image.path}`) ||
      null
    );
  }
  get title() {
    const { data } = this.props;
    return (
      (data && data.value && data.value.title && data.value.title.text) || ""
    );
  }
  get description() {
    const { data } = this.props;
    return (data && data.value && data.value.description) || "";
  }
  get logoStyle() {
    const { logoLoaded } = this.state;
    return !logoLoaded
      ? {
        backgroundColor: getBackgroundColorCode(),
      }
      : {};
  }
  render() {
    const { sectionIndex } = this.props;
    return (
      <GridRow className="nw-brandstory">
        <GridColumn className="nwc-grid-col-sm-6 nw-brandstory-storysection">
          {this.logoImage && (
            <LazyLoad>
            <LazyImg
              className="nw-brandstory-logoimage"
              rootMargin={`1500px`}
              src={this.logoImage}
              alt={imageFileName(this.logoImage)}
              style={this.logoStyle}
              onLoad={this.onLogoImageLoad}
              onWinLoad={isIframe}
            />
            </LazyLoad>
          )}
          <h3 className="nw-brandstory-storytitle">{this.title}</h3>
          <div className="nw-brandstory-description">{this.description}</div>
        </GridColumn>
        <GridColumn className="nwc-grid-col-sm-6 nw-brandstory-videosection">
          <StoryComponent.component
            sectionIndex={sectionIndex}
            data={this.modifiedData}
          />
        </GridColumn>
      </GridRow>
    );
  }
}

BrandStory.propTypes = {
  componentIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  data: PropTypes.shape({}).isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandStory,
  component: BrandStory,
};

export default ProjectComponent;
