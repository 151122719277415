import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Carousel,
  GridRow,
  GridColumn,
  Button,
  Utils,
} from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  APP_BREAKPOINTS,
  MASTER_TEMPLATE_COMPONENT_CONFIGS,
} from "npmlinks-constants";
import StoryComponent from "../StoryComponent";
import Event from "../../../../analytics/eventFactory";
import Analytics from "../../../../analytics";

@inject("BrandStore")
@observer
class SectionCarousel extends Component {
  static arrowBase() {
    return MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.ARROWS.base;
  }
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      isServer: true,
      scrollTime: 600,
      pauseScroll: false,
    };
    this.renderItems = this.renderItems.bind(this);
    this.onSwipe = this.onSwipe.bind(this);
    this.scroll = this.scroll.bind(this);
    this.getCurrentSize = this.getCurrentSize.bind(this);
    this.carouselSize = this.getCurrentSize();
    this.currentIndecesInView = Array.from(Array(this.carouselSize).keys());
    this.updateIndex = this.updateIndex.bind(this);
    this.triggerGA = this.triggerGA.bind(this);
    this.clearInterval = null;
    this.resetAutoScroll = this.resetAutoScroll.bind(this);
    this.pauseScroll = this.pauseScroll.bind(this);
    this.init = this.init.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->SectionCarousel", this.props);
    this.init();
    this.setState({ isServer: false });
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error SectionCarousel ${error} ${errorInfo} ${this}`,
    );
  }
  triggerGA(direction) {
    const {
      sectionIndex,
      componentIndex,
      data: { identity },
    } = this.props;
    if (this.props.BrandStore.isBT) {
      Analytics.trackEvent({
        category: this.props.BrandStore.isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.STORY_CAROUSEL,
        ]),
        label: Event.label.getMasterTemplateLabels([
          direction,
          componentIndex,
          identity,
        ]),
      });
    }
  }
  getCurrentSize() {
    if (this.carouselType === "single") {
      return 1;
    }
    const propSize = (this.list[0] && this.list[0].width) || 12;
    return Math.floor(12 / propSize);
  }
  onSwipe(direction, auto) {
    if (!auto) {
      this.resetAutoScroll();
    }
    const { pauseScroll } = this.state;
    if (pauseScroll && auto) {
      return null;
    }
    const currentWidth = window.innerWidth;
    if (currentWidth < APP_BREAKPOINTS.XS && this.carouselType !== "single") {
      return null;
    }
    this.scroll(direction.horizontal, auto);
    return null;
  }
  get showButtons() {
    return this.carouselSize < this.list.length;
  }
  get eventAction() {
    const { sectionIndex } = this.props;
    return Event.action.getMasterTemplateActions([
      sectionIndex,
      Event.action.MASTER_TEMPLATE_PAGES.SECTION_CAROUSEL,
    ]);
  }
  get list() {
    const { data } = this.props;
    return (data && data.list) || (data && data.children) || [];
  }
  get carouselType() {
    const {
      data: { carouselType },
    } = this.props;
    return carouselType || "multiple";
  }

  scroll(type, auto) {
    if (!auto) {
      this.triggerGA(type);
    }
    const index =
      type === "next"
        ? this.currentIndecesInView[this.carouselSize - 1]
        : this.currentIndecesInView[0];
    let newIndex = type === "next" ? index + 1 : index - 1;
    this.decideScrollTime(type, index);
    if (newIndex === this.list.length) {
      newIndex = 0;
      this.currentIndecesInView.splice(0, 1);
      this.currentIndecesInView.push(newIndex);
      this.setState({ index: newIndex });
    }
    if (newIndex > -1 && newIndex < this.list.length) {
      if (type === "next") {
        this.currentIndecesInView.splice(0, 1);
        this.currentIndecesInView.push(newIndex);
      } else {
        this.currentIndecesInView.splice(this.carouselSize - 1, 1);
        this.currentIndecesInView.unshift(newIndex);
      }
      this.setState({ index: newIndex });
    }
  }

  decideScrollTime(type, index) {
    const listLength = this.list.length;
    let newScrollTime;
    if (type === "prev") {
      newScrollTime =
        index - 1 > -1
          ? MASTER_TEMPLATE_COMPONENT_CONFIGS.SECTION_CAROUSEL.TRANSITION_TIME
              .SLOW
          : MASTER_TEMPLATE_COMPONENT_CONFIGS.SECTION_CAROUSEL.TRANSITION_TIME
              .QUICK;
    } else {
      newScrollTime =
        index + 1 < listLength
          ? MASTER_TEMPLATE_COMPONENT_CONFIGS.SECTION_CAROUSEL.TRANSITION_TIME
              .SLOW
          : MASTER_TEMPLATE_COMPONENT_CONFIGS.SECTION_CAROUSEL.TRANSITION_TIME
              .QUICK;
    }
    this.setState({ scrollTime: newScrollTime });
  }

  updateIndex(newIndex) {
    this.setState({ index: newIndex });
    const direction = newIndex > this.state.index ? "right" : "left";
    this.triggerGA(direction);
    if (this.currentIndecesInView.includes(newIndex)) {
      Utils.noop();
    } else if (newIndex < this.currentIndecesInView[0]) {
      this.currentIndecesInView.splice(this.currentIndecesInView.length - 1, 1);
    } else {
      this.currentIndecesInView.splice(0, 1);
    }
    this.currentIndecesInView.push(newIndex);
    this.currentIndecesInView = Array.from(new Set(this.currentIndecesInView)).sort();
  }

  renderItems(data, index, iterationIndex, parentData) {
    const { className } = this.props;
    const value = (this.carouselType === "single" && "12") || data.width;
    return (
      <div
        key={iterationIndex}
        className={`nw-sectioncarouselitem nwc-grid-col-sm-${value} ${className} is-${
          this.carouselType
        }`}
      >
        <StoryComponent.component
          index={index}
          data={data}
          eventAction={this.eventAction}
          componentIndex={iterationIndex}
          parentData={parentData}
          className={className}
        />
      </div>
    );
  }

  resetAutoScroll() {
    if (this.clearInterval) {
      clearInterval(this.clearInterval);
    }
    this.init();
  }

  pauseScroll(pauseScroll) {
    this.setState({ pauseScroll });
  }

  init() {
    this.clearInterval = setInterval(() => {
      this.onSwipe({ horizontal: "next" }, true);
    }, MASTER_TEMPLATE_COMPONENT_CONFIGS.SECTION_CAROUSEL.SCROLL_INTERVAL);
  }

  render() {
    const { data } = this.props;
    const { index, scrollTime, pauseScroll } = this.state;
    const { isServer } = this.state;
    return (
      <GridRow
        className={`nw-sectioncarousel is-${this.carouselType}`}
        onMouseEnter={() => this.pauseScroll(true)}
        onMouseLeave={() => this.pauseScroll(false)}
      >
        {this.showButtons && (
          <GridColumn
            className={`nw-sectioncarousel-button-container is-left nwc-grid-col-xs-1 is-${this.carouselType}`}
          >
            <Button
              className={`nw-sectioncarousel-button is-${
                this.carouselType
              } ${(isServer && "nwc-hide") || ""}
              ${pauseScroll ? "" : "nwc-hide"}`}
              disabled={index === 0}
              onClick={() => this.onSwipe({ horizontal: "prev" })}
            >
              <i
                className={`${SectionCarousel.arrowBase()}arrow_left nw-sectionicon is-${
                  this.carouselType
                }`}
              />
            </Button>
          </GridColumn>
        )}
        <GridColumn
          className={`nw-sectioncarousel-container nwc-grid-col-sm-${((this
            .carouselType === "single" ||
            !this.showButtons) &&
            "12") ||
            "10"} is-${this.carouselType}`}
        >
          <Carousel
            animationDuration={scrollTime}
            onSwipe={this.onSwipe}
            className={` is-${this.carouselType} nw-sectioncarouselenclose ${
              this.list.length <= this.carouselSize ? "is-noscroll" : ""
            }`}
            index={index}
            items={this.list}
            renderItems={(item, iterationIndex) =>
              this.renderItems(item, index, iterationIndex, data)
            }
          />
        </GridColumn>
        {this.showButtons && (
          <GridColumn
            className={`nw-sectioncarousel-button-container is-right nwc-grid-col-xs-1 is-${
              this.carouselType
            }`}
          >
            <Button
              disabled={index === this.list.length - 1}
              onClick={() => this.onSwipe({ horizontal: "next" })}
              className={`nw-sectioncarousel-button is-${
                this.carouselType
              }  ${(isServer && "nwc-hide") || ""} ${
                pauseScroll ? "" : "nwc-hide"
              }`}
            >
              <i
                className={`${SectionCarousel.arrowBase()}arrow_right nw-sectionicon is-${
                  this.carouselType
                }`}
              />
            </Button>
          </GridColumn>
        )}
        {this.carouselType === "single" && this.list.length > 1 && (
          <GridColumn
            className={`nw-storycarousel-dotswrapper is-${this.carouselType}`}
          >
            {this.list.map((item, iterIndex) => (
              <Button
                className={`nw-storycarousel-dots is-${
                  this.carouselType
                } ${(index === iterIndex && "is-active") || ""}`}
                key={item.type}
                onClick={() => this.updateIndex(iterIndex)}
              />
            ))}
          </GridColumn>
        )}
      </GridRow>
    );
  }
}

SectionCarousel.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  componentIndex: PropTypes.number.isRequired,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SectionCarousel,
  component: SectionCarousel,
};

export default ProjectComponent;
