import React, { Component } from "react";
import PropTypes from "prop-types";
import ProductCarouselComponent from "../ProductCarouselComponent";

class NewArrivalsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modifiedData: {},
    };
    this.init = this.init.bind(this);
  }
  componentDidMount() {
    this.init();
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error NewArrivalsCarousel ${error} ${errorInfo} ${this}`,
    );
  }
  init() {
    const { data } = this.props;
    const {
      value: { visible = false, title, identity },
    } = data;
    if (visible) {
      const modifiedData = {
        identity,
        storyType: {
          type: "newArrivals",
          pcmData: "",
        },
        title,
      };
      this.setState({ modifiedData, visible });
    }
  }
  render() {
    const { modifiedData, visible } = this.state;
    const { sectionIndex, hideComponent } = this.props;
    if (!visible) {
      return null;
    }
    return (
      <div className="nw-newarrivalscarousel">
        <ProductCarouselComponent.component
          sectionIndex={sectionIndex}
          data={modifiedData}
          hideComponent={hideComponent}
        />
      </div>
    );
  }
}

NewArrivalsCarousel.propTypes = {
  hideComponent: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  data: PropTypes.shape({}).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: NewArrivalsCarousel,
  component: NewArrivalsCarousel,
};

export default ProjectComponent;
