import React, { Component } from "react";
import { observer, inject } from "mobx-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { MASTER_TEMPLATE_COMPONENT_CONFIGS } from "npmlinks-constants";
import PropTypes from "prop-types";
import Node from "./Node";
import { NavMethods } from "../../../../utils";
// import Status from "./status";

@inject("BrandStore", "CommonStore")
@observer
class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleNodeList: {},
      list: [],
    };
    this.setVisibleNode = this.setVisibleNode.bind(this);
    this.getVisibleNode = this.getVisibleNode.bind(this);
    this.checkHide = this.checkHide.bind(this);
    this.initList = this.initList.bind(this);
  }
  componentDidMount() {
    this.checkHide();
    this.initList();
    console.log("ComponentPool->LeftNav", this.props);
  }

  componentDidCatch(error, errorInfo) {
    console.error(`ComponentPool->error LeftNav ${error} ${errorInfo} ${this}`);
  }
  get title() {
    const { data } = this.props;
    const { value } = data;
    const { title } = value || data;
    return title && title.hasOwnProperty("text") ? title.text : title;
  }

  initList() {
    const { data } = this.props;
    const { value, navpojo } = data;
    const { list = [] } = value || data;
    const { isDesktop } = this.props.CommonStore;
    const finalList = navpojo
      ? NavMethods.processLeftNav(navpojo.navtree, navpojo.rootKeys, isDesktop)
      : list;
    const newList = finalList.map(childnode => {
      const newObj = {
        isActive: false,
        key: childnode.type + childnode.level + childnode.label,
      };
      return Object.assign({}, childnode, newObj);
    });
    this.setState({ list: newList });
  }
  setVisibleNode(node) {
    this.setState(prevState => {
      const newState = prevState;
      newState.visibleNodeList = {};
      newState.visibleNodeList[node.key] = !node.isActive;
      node.isActive = !node.isActive; //eslint-disable-line
      return newState;
    });
  }
  getVisibleNode(node) {
    if (node) {
      return this.state.visibleNodeList[node.key];
    }
    return this.state.visibleNodeList;
  }
  checkHide() {
    const { hideComponent } = this.props;
    const { templateType } = this.props.BrandStore;
    const hideAccordingToTemplate = MASTER_TEMPLATE_COMPONENT_CONFIGS.LEFT_NAV.HIDE.includes(
      templateType,
    );
    if (hideAccordingToTemplate) {
      hideComponent();
    }
  }
  render() {
    const { list } = this.state;
    let childnodes = null;
    const { sectionIndex, componentIndex, data:{identity} } = this.props;
    // the Node component calls itself if there are children
    if (list && list.length) {
      childnodes = list.map((childnode, index) => (
        <Node.component
          className={`nw-leftnavlist-node-${childnode.level}`}
          node={childnode}
          list={childnode.children}
          setVisibleNode={() => this.setVisibleNode(childnode)}
          getVisibleNode={this.getVisibleNode}
          itemIndex={index}
          sectionIndex={sectionIndex}
          componentIndex={componentIndex}
          BrandStore={this.props.BrandStore}
          identity={identity}
        />
      ));
    }
    return (
      <div className="nw-leftnav">
        {this.title && <div className="nw-leftnav-title"> {this.title} </div>}
        <ul className="nw-leftnav-list">{childnodes}</ul>
      </div>
    );
  }
}

LeftNav.wrappedComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired,
  hideComponent: PropTypes.func.isRequired,
  data: PropTypes.shape({
    value: PropTypes.shape({}),
  }).isRequired,
  BrandStore: PropTypes.shape({
    templateType: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

export { default as Node } from "./Node";

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LeftNav.wrappedComponent,
  component: LeftNav,
};

export default ProjectComponent;
