import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Utils, Modal, Button } from "arv-reactcomponents";
import { deduceValuesFromPipedData, sanitiseUrl } from "../../../../../utils";

class HTML5Video extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVideoOpen: false,
      isVideoEnded: false,
    };

    this.timeoutId = null;

    this.playVideo = this.playVideo.bind(this);
    this.playModalVideo = this.playModalVideo.bind(this);
    this.openVideo = this.openVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);
    this.replayVideo = this.replayVideo.bind(this);
  }

  componentDidMount() {
    this.pauseVideoIfOutOfView();
  }

  get getTypeObj() {
    const { storyType: { pcmData = "", externalURL = "" } } = this.props;
    const { id, url } = deduceValuesFromPipedData(pcmData);
    const retUrl = url || sanitiseUrl(externalURL);
    return {
      styleId: id,
      url: retUrl,
    };
  }

  get videoEndedClassName() {
    return (!this.state.isVideoEnded && "nwc-hide") || "";
  }

  playVideo(ref) {
    if (ref) {
      this.bannerVideo = ref;
      Utils.requestAnimationFrame(ref.play);
    }
  }

  playModalVideo(ref) {
    if (ref) {
      this.modalVideo = ref;
      ref.play();
      ref.addEventListener(
        "play",
        () => {
          this.setState({
            isVideoEnded: false,
          });
        },
        false,
      );
      ref.addEventListener(
        "ended",
        () => {
          this.setState({
            isVideoEnded: true,
          });
        },
        false,
      );
    }
  }

  openVideo() {
    this.setState({
      isVideoOpen: true,
    });
  }

  closeVideo() {
    this.setState({
      isVideoOpen: false,
    });
  }

  replayVideo() {
    Utils.requestAnimationFrame(this.modalVideo.play);
  }

  pauseVideoIfOutOfView() {
    const elemBounds = Utils.getBoundClientRect(this.bannerVideo);
    const elemVerticalPosAvg = (elemBounds.top + elemBounds.bottom) / 2;

    Utils.onElementScroll(window, () => {
      clearTimeout(this.timeoutId);
      const windowBounds = Utils.windowScroll();
      if (
        windowBounds.top < elemVerticalPosAvg &&
        windowBounds.bottom > elemVerticalPosAvg &&
        this.bannerVideo.paused
      ) {
        this.timeoutId = setTimeout(() => {
          this.bannerVideo.play();
        }, 300);
      } else if (
        (windowBounds.top > elemVerticalPosAvg ||
          windowBounds.bottom < elemVerticalPosAvg) &&
        !this.bannerVideo.paused
      ) {
        this.bannerVideo.pause();
      }
    });
  }

  render() {
    const { backgroundColor, previewVideo, bleedVideo } = this.props;

    const { isVideoOpen } = this.state;

    return (
      <Fragment>
        <div className="nw-topbanner-videowrapper" style={{ backgroundColor }}>
          <video
            ref={this.playVideo}
            className="nw-topbanner-video"
            autoPlay
            loop
            muted
          >
            <track kind="captions" label="Campaign" />
            <source src={previewVideo.url} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="nw-topbanner-ctas">
            <Button className="nw-topbanner-ctabtns" onClick={this.openVideo}>
              {previewVideo.callToAction}
            </Button>
            <a
              href={this.getTypeObj.url}
              className="nwc-btn nw-topbanner-ctabtns"
              target="_blank"
            >
              {bleedVideo.callToAction}
            </a>
          </div>
        </div>
        <Modal
          className="nw-topbanner-videomodal"
          isOpen={isVideoOpen}
          onClose={this.closeVideo}
        >
          <div className="nw-topbanner-videowrapper is-modal">
            <video
              ref={this.playModalVideo}
              className="nw-topbanner-video"
              autoPlay
            >
              <track kind="captions" label="Campaign" />
              <source src={bleedVideo.url} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            <div className={`nw-topbanner-ctas ${this.videoEndedClassName}`}>
              <Button
                className="nw-topbanner-ctabtns"
                onClick={this.replayVideo}
              >
                <i className="icomoon-rotate-left" />
              </Button>
              <a
                href={this.getTypeObj.url}
                className="nwc-btn nw-topbanner-ctabtns"
                target="_blank"
              >
                {bleedVideo.callToAction}
              </a>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

HTML5Video.propTypes = {
  previewVideo: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    callToAction: PropTypes.string,
  }).isRequired,
  bleedVideo: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    callToAction: PropTypes.string,
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  storyType: PropTypes.shape({
    externalURL: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: HTML5Video,
  component: HTML5Video,
};

export default ProjectComponent;
