import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { APP_BREAKPOINTS, PDP_CAROUSEL_SIZE, pageType, MASTERTEMPLATE } from "npmlinks-constants";
import { GridRow, GridColumn, Carousel, Button } from "arv-reactcomponents";
import ProductView from "../../../component/ProductList/ProductView";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { isIframe } from "../../../utils";

const ARROW_CLASSNAMES = {
  "nw-mt-se nw-mt-brandmastertemplate2": "icomoon-sephora_arrow_",
  "nw-mt-ga nw-mt-brandmastertemplate3": "icomoon-sephora_arrow_",
};

@inject("BrandStore", "CommonStore")
@observer
class CarouselComponent extends Component {
  static getCurrentWidth(value) {
    const newVal = parseFloat(100 / value).toFixed(2);
    return {
      width: `${newVal}%`,
    };
  }
  static renderItems(
    isDesktop,
    item,
    index,
    currentSize,
    iterationIndex,
    origin,
    className,
    title,
    identity,
    sectionIndex,
    componentIndex,
  ) {
    const onWinLoad = !isDesktop || isIframe;
    return (
      <div
        className={`nw-pdpcarouselitem ${className}`}
        style={CarouselComponent.getCurrentWidth(currentSize)}
        key={iterationIndex}
      >
        <ProductView.component
          title={title}
          currentIndex={index}
          index={iterationIndex}
          link={item.url}
          mainImage={item.imagePath}
          altImage={item.altImagePath}
          imageWidth={item.imageWidth}
          imageHeight={item.imageHeight}
          imageColor={item.imageColor}
          brandText={item.brandName || item.brand}
          productTitle={item.name}
          isNew={item.isNew}
          discountRange={item.discountRange}
          mrpRange={item.mrpRange}
          sellingPriceRange={item.sellingPriceRange}
          promotions={item.promotions || []}
          isAllPromotions={false}
          styleId={item.styleId || item.id}
          moreColors={item.moreColors}
          onWinLoad={onWinLoad}
          origin={origin}
          inStock={item.inStock}
          productTags={item.productTags}
          identity={identity}
          sectionIndex={sectionIndex}
          componentIndex={componentIndex}
        />
      </div>
    );
  }
  static getCurrentSize(propsVal) {
    const currentWidth = window.innerWidth;
    let size = "LG";
    if (currentWidth < APP_BREAKPOINTS.XS) {
      size = "XS";
    } else if (currentWidth < APP_BREAKPOINTS.SM) {
      size = "SM";
    } else if (currentWidth < APP_BREAKPOINTS.MD) {
      size = "MD";
    }
    const valFromConfig = PDP_CAROUSEL_SIZE[size];
    let valFromProps = propsVal[size] || 10;
    valFromProps = parseInt(valFromProps, 10);
    return valFromProps || valFromConfig;
  }
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    this.scroll = this.scroll.bind(this);
    this.onSwipe = this.onSwipe.bind(this);
    this.carouselSize = CarouselComponent.getCurrentSize(props.size);
    this.currentIndecesInView = Array.from(Array(this.carouselSize).keys());
    this.triggerGA = this.triggerGA.bind(this);
  }
  triggerGA(direction) {
    const { sectionIndex, componentIndex, identity } = this.props;
    if(window.pageType === pageType.pdp){
      Analytics.trackEvent({
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.BT_PCAR_CHANGE,
        ]),
        label: Event.label.getMasterTemplateLabels([
          direction,
          componentIndex,
          identity,
        ]),
      });
    }
    else if (window.pageType === MASTERTEMPLATE.BT) {
      Analytics.trackEvent({
        category: this.props.BrandStore.isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.BT_PCAR_CHANGE,
        ]),
        label: Event.label.getMasterTemplateLabels([
          direction,
          componentIndex,
          identity,
        ]),
      });
    }
  }
  onSwipe(direction) {
    const currentWidth = window.innerWidth;
    if (currentWidth < APP_BREAKPOINTS.SM) return null;
    this.scroll(direction.horizontal);
    return null;
  }
  get arrowClassName() {
    const { className } = this.props;
    return ARROW_CLASSNAMES[className] || "icomoon-arrow_";
  }
  scroll(type, event) {
    this.triggerGA(type);
    if (event) event.preventDefault();
    const index =
      type === "next"
        ? this.currentIndecesInView[this.carouselSize - 1]
        : this.currentIndecesInView[0];
    const newIndex = type === "next" ? index + 1 : index - 1;
    if (newIndex > -1 && newIndex < this.props.list.length) {
      if (type === "next") {
        this.currentIndecesInView.splice(0, 1);
        this.currentIndecesInView.push(newIndex);
      } else {
        this.currentIndecesInView.splice(this.carouselSize - 1, 1);
        this.currentIndecesInView.unshift(newIndex);
      }
      this.setState({ index: newIndex });
    }
  }

  render() {
    const {
      list,
      origin,
      className,
      title,
      identity,
      sectionIndex,
      componentIndex,
    } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const { index } = this.state;
    return (
      <GridRow className="nw-pdpcarouselrow">
        <GridColumn
          className={`nw-productcarousel-btncol nw-productcarousel-btncol-left ${className}`}
        >
          {list.length > this.carouselSize ? (
            <Button
              disabled={index === 0}
              className={`nwc-btn-primary-txt nw-pdpcarouselbutton ${className}`}
              onClick={e => this.scroll("prev", e)}
            >
              <i
                className={`${
                  this.arrowClassName
                }left nw-pdpcarouselicon ${className}`}
              />
            </Button>
          ) : (
            <div />
          )}
        </GridColumn>
        <GridColumn className={`nw-pdpcarouselcolumn ${className}`}>
          <Carousel
            onSwipe={this.onSwipe}
            className={`nw-pdpcarouselenclose  ${className} ${
              (isDesktop && list.length <= this.carouselSize) ||
              (!isDesktop && list.length < 3)
                ? "is-noscroller"
                : ""
            }`}
            index={index}
            items={list}
            renderItems={(item, iterationIndex) =>
              CarouselComponent.renderItems(
                isDesktop,
                item,
                index,
                this.carouselSize,
                iterationIndex,
                origin,
                className,
                title,
                identity,
                sectionIndex,
                componentIndex,
              )
            }
          />
        </GridColumn>
        <GridColumn
          className={`nw-productcarousel-btncol nw-productcarousel-btncol-right ${className}`}
        >
          {list.length > this.carouselSize ? (
            <Button
              disabled={index === list.length - 1}
              className={`nwc-btn-primary-txt nw-pdpcarouselbutton ${className}`}
              onClick={e => this.scroll("next", e)}
            >
              <i
                className={`${
                  this.arrowClassName
                }right nw-pdpcarouselicon ${className}`}
              />
            </Button>
          ) : (
            <div />
          )}
        </GridColumn>
      </GridRow>
    );
  }
}

CarouselComponent.wrappedComponent.defaultProps = {
  title: "",
  identity: null,
};
CarouselComponent.wrappedComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired,
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.shape({}).isRequired,
  origin: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  identity: PropTypes.string,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: CarouselComponent.wrappedComponent,
  component: CarouselComponent,
};

export default ProjectComponent;
