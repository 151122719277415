import React, { Component } from "react";
import PropTypes from "prop-types";
import { Carousel, Button } from "arv-reactcomponents";
import { MASTER_TEMPLATE_COMPONENT_CONFIGS } from "npmlinks-constants";
import StoryComponent from "../StoryComponent";
import Event from "../../../../analytics/eventFactory";

class BannerCarousel extends Component {
  static isActive(dotIndex, index) {
    return dotIndex === index ? "is-active" : "";
  }
  static arrowBase() {
    return MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.ARROWS.base;
  }
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      scrollTime: 600,
      pauseScroll: false,
    };
    this.clearInterval = null;
    this.init = this.init.bind(this);
    this.onSwipe = this.onSwipe.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.resetAutoScroll = this.resetAutoScroll.bind(this);
    this.pauseScroll = this.pauseScroll.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }
  componentDidMount() {
    this.init();
  }

  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error Banner carousel ${error} ${errorInfo} ${this}`,
    );
  }
  get validatedList() {
    const { data } = this.props;
    return (
      (data &&
        data.list &&
        data.list.filter(
          item => item.images && item.images[0] && item.images[0].path,
        )) ||
      []
    );
  }

  onSwipe(direction, auto) {
    if (!auto) {
      this.resetAutoScroll();
    }
    const { pauseScroll } = this.state;
    if (pauseScroll && auto) {
      return null;
    }
    const action = direction.horizontal;
    const listLength = this.props.data.list.length;
    const { index } = this.state;
    let newIndex;
    let newScrollTime;
    if (action === "prev") {
      newIndex = index - 1 > -1 ? index - 1 : listLength - 1;
      newScrollTime =
        index - 1 > -1
          ? MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.TRANSITION_TIME
              .SLOW
          : MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.TRANSITION_TIME
              .QUICK;
    } else {
      newIndex = index + 1 < listLength ? index + 1 : 0;
      newScrollTime =
        index + 1 < listLength
          ? MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.TRANSITION_TIME
              .SLOW
          : MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.TRANSITION_TIME
              .QUICK;
    }
    this.setState({ index: newIndex, scrollTime: newScrollTime });
    return null;
  }
  get eventAction() {
    const { sectionIndex } = this.props;
    return Event.action.getMasterTemplateActions([
      sectionIndex,
      Event.action.MASTER_TEMPLATE_PAGES.BANNER_CAROUSEL,
    ]);
  }
  get noDescriptions() {
    const { data = {} } = this.props;
    const { list = [] } = data;
    return list
      .map(item => {
        const title = (item && item.title && item.title.text) || "";
        const subtitle = (item && item.subtitle && item.subtitle.text) || "";
        const cta = (item && item.callToAction) || "";
        return title || subtitle || cta;
      })
      .filter(item => item).length > 0
      ? ""
      : "nw-nocontent";
  }

  init() {
    this.clearInterval = setInterval(() => {
      this.onSwipe({ horizontal: "next" }, true);
    }, MASTER_TEMPLATE_COMPONENT_CONFIGS.BANNER_CAROUSEL.SCROLL_INTERVAL);
  }
  scrollTo(index) {
    this.resetAutoScroll();
    this.setState({ index });
  }
  resetAutoScroll() {
    if (this.clearInterval) {
      clearInterval(this.clearInterval);
    }
    this.init();
  }
  pauseScroll(pauseScroll) {
    this.setState({ pauseScroll });
  }
  renderItems(data, index, iterationIndex) {
    const { className } = this.props;
    return (
      <div
        key={iterationIndex}
        className={`nw-bannercarouselitem ${className}`}
      >
        <StoryComponent.component
          className="nw-bannercarousel-story"
          index={index}
          data={data}
          eventAction={this.eventAction}
          componentIndex={iterationIndex}
        />
      </div>
    );
  }
  render() {
    const { className } = this.props;
    const { index, scrollTime, pauseScroll } = this.state;
    const list = this.validatedList;
    return (
      <div
        onMouseEnter={() => this.pauseScroll(true)}
        onMouseLeave={() => this.pauseScroll(false)}
        className={`nw-bannercarousel-container ${className}`}
      >
        {list.length > 1 && (
          <Button
            onClick={() => this.onSwipe({ horizontal: "prev" })}
            className={`nw-bannercarousel-button left nw-desktop-only ${className} ${
              pauseScroll ? "" : "nwc-hide"
            }`}
          >
            <i
              className={`${BannerCarousel.arrowBase()}arrow_left nw-bannercarouselicon`}
            />
          </Button>
        )}

        <Carousel
          animationDuration={scrollTime}
          onSwipe={this.onSwipe}
          className={`nw-bannercarouselenclose ${
            list.length <= 1 ? "is-noscroller" : ""
          }`}
          index={index}
          items={list}
          renderItems={(item, iterationIndex) =>
            this.renderItems(item, index, iterationIndex)
          }
        />
        {list.length > 1 ? (
          <div
            className={`nw-bannercarousel-dotswrapper ${className} ${
              this.noDescriptions
            }`}
          >
            {list.map((item, dotIndex) => (
              <Button
                key={item.images[0].path}
                onClick={() => this.scrollTo(dotIndex)}
                className={`nw-bannercarousel-dots ${BannerCarousel.isActive(
                  dotIndex,
                  index,
                )}`}
              />
            ))}
          </div>
        ) : (
          ""
        )}
        {list.length > 1 && (
          <Button
            onClick={() => this.onSwipe({ horizontal: "next" })}
            className={`nw-bannercarousel-button nw-desktop-only ${className} right ${
              pauseScroll ? "" : "nwc-hide"
            }`}
          >
            <i
              className={`${BannerCarousel.arrowBase()}arrow_right nw-bannercarouselicon`}
            />
          </Button>
        )}
      </div>
    );
  }
}

BannerCarousel.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BannerCarousel,
  component: BannerCarousel,
};

export default ProjectComponent;
