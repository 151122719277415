import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { Img } from "arv-reactcomponents";
import {
  deduceValuesFromPipedData,
  sanitiseUrl,
  imageFileName,
} from "../../../../utils";
import Video from "./Video";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import Anchor from "../../../Anchor";

@inject("BrandStore")
@observer
class TopBanner extends Component {
  constructor(props) {
    super(props);
    this.triggerGa = this.triggerGa.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->TopBanner", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error TopBanner ${error} ${errorInfo} ${this}`,
    );
  }
  get getTypeObj() {
    const { data } = this.props;
    const { storyType = null, type = null } = data.value;
    const { pcmData = "", externalURL = "" } = storyType || type;
    const pcmDataForBrand =
      (data.value && data.value.type && data.value.type.pcmData) || "";
    const { id, url } = deduceValuesFromPipedData(pcmData || pcmDataForBrand);
    const retUrl = url || sanitiseUrl(externalURL);
    return {
      styleId: id,
      url: retUrl,
    };
  }

  triggerGa() {
    const {
      sectionIndex,
      data: { identity },
    } = this.props;
    Analytics.trackEvent({
      category: window.pageType,
      action: Event.action.getMasterTemplateActions([
        sectionIndex,
        Event.action.MASTER_TEMPLATE_PAGES.TOP_BANNER,
      ]),
      label: Event.label.getMasterTemplateLabels([
        this.getTypeObj.url,
        identity,
      ]),
    });
  }
  render() {
    const { data } = this.props;

    const {
      value: { images, backgroundColor, storyType, previewVideo, bleedVideo },
    } = data;
    const [{ path, width = 0, height = 0 }] =
      images && images.length > 0 ? images : [{ path: null }];
    const prefixedPath = `${this.props.BrandStore.urlPrefix}${path}`;

    const paddingBottom = `${(height / width) * 100}%`;

    if (!previewVideo) {
      return (
        <Anchor.component
          className="nw-topbanner"
          href={this.getTypeObj.url}
          to={this.getTypeObj.url}
          style={{ paddingBottom, backgroundColor }}
          onClick={this.triggerGa}
        >
          {(prefixedPath && (
            <Img
              className="nw-topbanner-img"
              src={prefixedPath}
              alt={imageFileName(prefixedPath)}
            />
          )) ||
            null}
        </Anchor.component>
      );
    }

    return (
      <Video.component
        previewVideo={previewVideo}
        bleedVideo={bleedVideo}
        backgroundColor={backgroundColor}
        storyType={storyType}
      />
    );
  }
}

TopBanner.wrappedComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    value: PropTypes.shape({
      previewVideo: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
        callToAction: PropTypes.string,
      }),
      bleedVideo: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
        callToAction: PropTypes.string,
      }),
      images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      backgroundColor: PropTypes.string,
      storyType: PropTypes.shape({
        externalURL: PropTypes.string,
        type: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export { default as Video } from "./Video";

const getFinalComponent = component =>
  inject("BrandStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: TopBanner.wrappedComponent,
  component: TopBanner,
};

export default ProjectComponent;
