import React from "react";
import PropTypes from "prop-types";

function BrandStoryDescription(props) {
  const { data: { description }, className } = props;
  return (
    <p className={`nw-brandstorydescription ${className}`}>
      {description.text}
    </p>
  );
}

BrandStoryDescription.defaultProps = {
  className: "",
};
BrandStoryDescription.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.shape({}).isRequired,
  }).isRequired,
  className: PropTypes.string,
};
const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandStoryDescription,
  component: BrandStoryDescription,
};

export default ProjectComponent;
