import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { GridRow, Masonry } from "arv-reactcomponents";

import { getIsLandscape } from "../../../../utils";
import StoryComponent from "../StoryComponent";

const columnCount = { sm: 4, xs: 12 };

@inject("CommonStore", "BrandStore")
@observer
class StoryMasonry extends Component {
  constructor(props) {
    super(props);

    const { isDesktop } = this.props.CommonStore;

    this.renderList = this.renderList.bind(this);
    this.isMobileClassName =
      ((isDesktop || getIsLandscape()) && "is-desktop") || "is-mobile";

    this.odd = true;
  }
  componentDidMount() {
    console.log("ComponentPool->StoryMasonry", this.props);
    if (this.validateStories.length === 0) {
      this.props.hideComponent();
    }
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error StoryMasonry ${error} ${errorInfo} ${this}`,
    );
  }

  // @TODO: Remove when response corrected
  // To handle case for empty images
  get validateStories() {
    const { data: { value: list } } = this.props;
    return (
      list &&
      list
        .filter(item => item && item.images && item.images.length)
        .map((story, index) => {
          const returnStory = {
            ...story,
            index,
          };
          return returnStory;
        })
    );
  }

  renderList(item) {
    const { data, sectionIndex } = this.props;

    const dataKey = data.key.toLowerCase();
    const { brandJourneyId } = this.props.CommonStore;
    const { templateClass } = this.props.BrandStore;
    const isOddOrEvenClassName =
      (this.odd && "nw-storygrid-storyodd") || "nw-storygrid-storyeven";
    this.odd = !this.odd;

    const className = `nw-storymasonry-story ${
      this.isMobileClassName
    } ${isOddOrEvenClassName} nw-mt-${dataKey} nw-mt-${brandJourneyId} ${templateClass}`;
    return (
      <StoryComponent.component
        className={className}
        data={item}
        key={item.title.text}
        sectionIndex={sectionIndex}
        componentIndex={item.index}
      />
    );
  }

  render() {
    const { data } = this.props;
    const { brandJourneyId, isDesktop } = this.props.CommonStore;
    if (global && global.isServer && !isDesktop) {
      return null;
    }
    if (data && Array.isArray(data.value)) {
      return (
        <GridRow className={`nw-storymasonry nw-${brandJourneyId}`}>
          <Masonry
            data={this.validateStories}
            columnCount={columnCount}
            renderList={this.renderList}
          />
        </GridRow>
      );
    }
    return null;
  }
}

StoryMasonry.wrappedComponent.propTypes = {
  hideComponent: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  BrandStore: PropTypes.shape({
    templateClass: PropTypes.string,
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandJourneyId: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: StoryMasonry.wrappedComponent,
  component: StoryMasonry,
};

export default ProjectComponent;
