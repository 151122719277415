import React, { Component } from "react";
import PropTypes from "prop-types";
import Anchor from "../../../../Anchor";
import { sanitiseUrl } from "../../../../../utils";

class LeftNavModal extends Component {
  static currentSign(item) {
    if (item && item.children && item.children.length) {
      return item.isMenuOpen ? "-" : "+";
    }
    return "";
  }
  static showSubLevel(item) {
    return (
      (item && item.children && item.children.length && item.isMenuOpen) || null
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.init = this.init.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    const { data } = this.props;
    this.init(data);
  }
  get currentClassName() {
    const { index } = this.props;
    return `is-level${index}`;
  }
  init(data) {
    this.setState({ list: data });
  }
  toggle(event, index) {
    const { list } = this.state;
    const item = list[index];
    item.isMenuOpen = !item.isMenuOpen;
    if (item.children && item.children.length) {
      event.preventDefault();
    }

    this.setState({ list });
  }
  render() {
    const { list } = this.state;
    const { index } = this.props;
    return (
      <ul className={`nw-leftnavmobile-modallist ${this.currentClassName}`}>
        {list &&
          list.map((item, iterationIndex) => (
            <li className="nw-leftnavmobile-modallistitem">
              <Anchor.component
                className={`nw-leftnavmobile-modallistanchor ${
                  this.currentClassName
                }`}
                to={sanitiseUrl(item.url)}
                href={sanitiseUrl(item.url)}
                onClick={event => this.toggle(event, iterationIndex)}
                target="_self"
              >
                <span
                  className={`nw-leftnavmobile-modallabel ${
                    this.currentClassName
                  }`}
                >
                  {item.label}
                </span>
                <span
                  className={`nw-leftnavmobile-modaltogglesign ${
                    this.currentClassName
                  }`}
                >
                  {LeftNavModal.currentSign(item)}
                </span>
              </Anchor.component>
              {LeftNavModal.showSubLevel(item) && (
                <LeftNavModal data={item.children} index={index + 1} />
              )}
            </li>
          ))}
      </ul>
    );
  }
}

LeftNavModal.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: LeftNavModal,
  component: LeftNavModal,
};

export default ProjectComponent;
