import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import Anchor from "./../../../../Anchor";
import { isApp } from "../../../../../utils";
import Analytics from "../../../../../analytics";
import Event from "../../../../../analytics/eventFactory";

@inject("CommonStore", "BrandStore")
@observer
class Node extends Component {
  static linkTarget(isDesktop) {
    return (isDesktop && "_blank") || (isApp && "_self") || "";
  }

  constructor(props) {
    super(props);
    this.nodeClickAction = this.nodeClickAction.bind(this);
    this.nodeRender = this.nodeRender.bind(this);
    this.triggerGaOnNav = this.triggerGaOnNav.bind(this);
  }
  get getHref() {
    const { node } = this.props;
    return node.url;
  }

  /** put logic for showing expends or collapse logic when loading first time */
  get showChild() {
    const list = this.props.getVisibleNode();
    const { node } = this.props;
    if (node && node.isActive && list[node.key]) {
      return "";
    }
    return "nwc-hide";
  }
  triggerGaOnNav(node, itemIndex, href) {
    const {
      BrandStore: { isBT },
      sectionIndex,
      componentIndex,
      identity,
    } = this.props;
    const { label: nodelabel, type, nodeType } = node;
    if (isBT) {
      Analytics.trackEvent({
        category: isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.DESKTOP_NAV,
        ]),
        label: Event.label.getMasterTemplateLabels([
          componentIndex,
          nodelabel,
          itemIndex,
          identity,
        ]),
      });
      return null;
    }
    const action = Event.action.getMasterTemplateActions([
      itemIndex,
      Event.action.MASTER_TEMPLATE_PAGES.LEFT_NAV_DESKTOP,
    ]);
    const label = Event.label.getMasterTemplateLabels([
      type,
      nodelabel,
      nodeType,
      href,
    ]);
    Analytics.trackEvent({
      category: window.pageType,
      action,
      label,
    });
    return null;
  }
  nodeClickAction(e) {
    const { list, node, itemIndex } = this.props;
    this.triggerGaOnNav(node, itemIndex, this.getHref);
    if (list && list.length) {
      e.preventDefault();
      this.props.setVisibleNode(node);
    } else {
      // based on node type return true/false but false will do stop propagation also
      return true;
    }
    return true;
  }
  nodeRender(childnodes) {
    const { list, node, className, getVisibleNode } = this.props;
    const { isDesktop } = this.props.CommonStore;

    return (
      <li className={className} key={node.key}>
        <Anchor.component
          className={`nw-leftnav-listanchor ${
            node.isActive && getVisibleNode(node) ? "is-selected" : ""
          }`}
          to={this.getHref}
          href={this.getHref}
          target={Node.linkTarget(isDesktop)}
          onClick={this.nodeClickAction}
        >
          {node.label}
        </Anchor.component>
        {list && list.length ? (
          <ul
            className={`nw-leftnavlist-level${node.level} nw-node-level ${
              this.showChild
            }`}
          >
            {childnodes}
          </ul>
        ) : null}
      </li>
    );
  }

  render() {
    const {
      list,
      getVisibleNode,
      sectionIndex,
      componentIndex,
      BrandStore,
      CommonStore,
      identity,
    } = this.props;
    let childnodes = null;

    // the Node component calls itself if there are children
    if (list && list.length) {
      childnodes = list.map((childnode, index) => {
        const renderVal = Object.assign({}, childnode, {
          key: childnode.type + childnode.level + childnode.label,
        });
        return (
          <Node
            node={renderVal}
            list={renderVal.children}
            className={`nw-leftnavlist-node-${renderVal.level}`}
            itemIndex={index}
            getVisibleNode={getVisibleNode}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
            BrandStore={BrandStore}
            CommonStore={CommonStore}
            identity={identity}
          />
        );
      });
    }

    // return our li element Base Case
    return this.nodeRender(childnodes);
  }
}
Node.wrappedComponent.propTypes = {
  identity: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired,
  setVisibleNode: PropTypes.func.isRequired,
  getVisibleNode: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  node: PropTypes.shape({}).isRequired,
  list: PropTypes.shape({}).isRequired,
  itemIndex: PropTypes.string.isRequired,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));;

const ProjectComponent = {
  getFinalComponent,
  wrapper: Node.wrappedComponent,
  component: Node,
};

export default ProjectComponent;
