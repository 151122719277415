import React from "react";
import PropTypes from "prop-types";

const StoryTitle = props => {
  const { data } = props;
  return <h2 className="nw-storytitle">{data.value.text}</h2>;
};

StoryTitle.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.shape({}),
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: StoryTitle,
  component: StoryTitle,
};

export default ProjectComponent;
