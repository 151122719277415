import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Utils } from "arv-reactcomponents";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  MASTER_TEMPLATE_COMPONENT_MAPPING_MOBILE,
  MASTER_TEMPLATE_COMPONENT_MAPPING,
} from "npmlinks-constants";
import { getIsLandscape } from "../../../utils";
import * as ComponentPool from "../ComponentPool";

@inject("CommonStore", "BrandStore")
@observer
class ComponentDecider extends Component {
  static decideComponent(componentData, isDesktop) {
    const { type, key } = componentData;
    const mapping = type || key;
    if (
      !isDesktop &&
      !getIsLandscape() &&
      MASTER_TEMPLATE_COMPONENT_MAPPING_MOBILE[mapping]
    ) {
      return MASTER_TEMPLATE_COMPONENT_MAPPING_MOBILE[mapping];
    }
    return MASTER_TEMPLATE_COMPONENT_MAPPING[mapping];
  }

  constructor() {
    super();
    this.state = { module: null };
    this.currentClass = this.currentClass.bind(this);
    this.init = this.init.bind(this);
  }
  componentWillMount() {
    this.init();
  }
  componentDidCatch(error, errorInfo) {
    this.props.hideComponent();
  }
  init() {
    const { componentData, hideComponent } = this.props;
    const { isDesktop } = this.props.CommonStore;

    const pathForFile = ComponentDecider.decideComponent(componentData, isDesktop);
    if (!pathForFile) {
      hideComponent();
    } else {
      this.setState({ module: ComponentPool[pathForFile] });
    }
  }
  currentClass(parentData) {
    const type =
      (parentData && parentData.type && parentData.type.toLowerCase()) ||
      "section";
    const { brandJourneyId } = this.props.CommonStore;
    const { templateClass } = this.props.BrandStore;
    return `nw-mt-${type} nw-mt-${brandJourneyId} ${templateClass}`;
  }
  render() {
    const { componentData, parentData, hideComponent } = this.props;
    const { sectionIndex, componentIndex } = componentData;
    const { module: DecidedComponent } = this.state;
    if (!DecidedComponent) {
      return <div />;
    }
    return (
      <DecidedComponent.component
        data={componentData}
        parentData={parentData}
        className={this.currentClass(parentData)}
        hideComponent={hideComponent}
        sectionIndex={sectionIndex}
        componentIndex={componentIndex}
      />
    );
  }
}

ComponentDecider.wrappedComponent.defaultProps = {
  parentData: {},
  componentIndex: 0,
  hideComponent: Utils.noop,
};

ComponentDecider.wrappedComponent.propTypes = {
  componentIndex: PropTypes.number,
  sectionIndex: PropTypes.number.isRequired,
  hideComponent: PropTypes.func,
  componentData: PropTypes.shape({}).isRequired,
  parentData: PropTypes.shape({}),
  BrandStore: PropTypes.shape({
    templateClass: PropTypes.string,
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandJourneyId: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ComponentDecider.wrappedComponent,
  component: ComponentDecider,
};

export default ProjectComponent;
