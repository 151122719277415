import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import {
  deduceValuesFromPipedData,
  sanitiseUrl,
  imageFileName,
} from "../../../../utils";
import { Modal, Img } from "arv-reactcomponents";
import VideoComponent from "../VideoComponent";
import Anchor from "../../../Anchor";

import TitleSubtitleCta from "./TitleSubtitleCta";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";

@inject("BrandStore")
@observer
class Banner extends Component {
  static triggerGa(sectionIndex, bannerType, identity) {
    const action = Event.action.getMasterTemplateActions([
      sectionIndex,
      Event.action.MASTER_TEMPLATE_PAGES.LEFT_NAV_DESKTOP,
    ]);
    const labelArray = identity ? [identity] : [];
    const label = Event.label.getMasterTemplateLabels(labelArray);
    Analytics.trackEvent({
      category: window.pageType,
      action,
      label,
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
    this.bannerClick = this.bannerClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->component Banner", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error Bannercomponent ${error} ${errorInfo} ${this}`,
    );
  }

  get bannerPcmData() {
    const { bannerData = {} } = this.getBannerData;
    const { type: { pcmData, externalURL } = {} } = bannerData;
    const { id, url } = deduceValuesFromPipedData(pcmData);
    const retUrl = url || sanitiseUrl(externalURL);
    return {
      collectionId: id,
      url: retUrl,
    };
  }
  get getBannerData() {
    const {
      data: {
        value: { banner: bannerData = {}, bannerType } = {},
        identity = null,
      },
      sectionIndex = 0,
    } = this.props;
    const { type: { type: bannelLinkType } = {} } = bannerData;
    if (bannelLinkType === "youtubeURL") {
      bannerData.video = bannerData.type;
    }
    return Object.assign(
      {},
      { bannerData },
      { bannerType },
      { sectionIndex },
      { identity },
    );
  }

  bannerClick(event) {
    const {
      bannerData = {},
      sectionIndex,
      bannerType,
      identity,
    } = this.getBannerData;
    const { type: { url: videoUrl } = {} } = bannerData;
    if (videoUrl) {
      event.preventDefault();
      this.setState({ isModalOpen: true });
    }
    Banner.triggerGa(sectionIndex, bannerType, identity);
  }
  closeModal() {
    this.setState({ isModalOpen: false });
  }
  render() {
    const { bannerData = {}, sectionIndex } = this.getBannerData;
    const {
      images,
      backgroundColor,
      callToAction,
      title,
      subtitle,
    } = bannerData;
    const [{ path, width = 0, height = 0 }] =
      images && images.length > 0 ? images : [{ path: null }];
    const prefixedPath = `${this.props.BrandStore.urlPrefix}${path}`;

    const paddingBottom = height && width ? `${(height / width) * 100}%` : 0;
    return (
      <div
        className="nw-banner-container"
        role="presentation"
        onClick={this.bannerClick}
      >
        <Anchor.component
          className="nw-banner"
          to={this.bannerPcmData.url}
          href={this.bannerPcmData.url}
          style={{ paddingBottom, backgroundColor }}
          target="_self"
        >
          {(prefixedPath && (
            <Img
              className="nw-banner-img"
              src={prefixedPath}
              alt={imageFileName(prefixedPath)}
            />
          )) ||
            null}
        </Anchor.component>
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          className="nw-banner-videopopup"
        >
          {" "}
          <VideoComponent.component
            data={bannerData}
            autoPlay
            modalView
            allowFullScreen
            showTitle={false}
            sectionIndex={sectionIndex}
          />
        </Modal>
        <TitleSubtitleCta.component
          ctaText={callToAction}
          title={title}
          subtitle={subtitle}
        />
      </div>
    );
  }
}

Banner.wrappedComponent.defaultProps = {
  data: {
    value: {},
  },
};

Banner.wrappedComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    value: PropTypes.shape({}),
  }),
};

export { default as TitleSubtitleCta } from "./TitleSubtitleCta";

const getFinalComponent = component =>
  inject("BrandStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Banner.wrappedComponent,
  component: Banner,
};

export default ProjectComponent;
