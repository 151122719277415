import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import CmtCarousel from "../CmtCarousel";

@inject("BrandStore")
@observer
class Blocks extends PureComponent {
  componentDidMount() {
    console.log("ComponentPool->Blocks", this.props);
  }
  componentDidCatch(error, info) {
    console.error(
      `ComponentPool->error Blocks component ${error} ${info} ${this}`,
    );
  }
  get sortedBlocks() {
    const { data: { value } } = this.props;
    const { brandId } = this.props.BrandStore;
    const { newArrivals, onSale, blocks } = value;
    const retArray = [];
    if (newArrivals.visible) {
      newArrivals.params = "&na";
      newArrivals.id = `Collections||${brandId}`;
      retArray.push(newArrivals);
    }
    if (onSale.visible) {
      onSale.params = "&os";
      onSale.id = `Collections||${brandId}`;
      retArray.push(onSale);
    }
    return [...retArray, ...blocks];
  }
  render() {
    return this.sortedBlocks.map(block => (
      <CmtCarousel.component data={block} />
    ));
  }
}
Blocks.propTypes = {
  BrandStore: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    value: PropTypes.shape({}),
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Blocks.wrappedComponent,
  component: Blocks,
};

export default ProjectComponent;
