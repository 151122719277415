import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

@inject("CommonStore")
@observer
class PaddingComponent extends Component {
  get currentStyle() {
    const { data = {} } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const keyToBeUsed = isDesktop ? "desktop" : "mobile-site";
    if (!data[keyToBeUsed]) {
      return { backgroundColor: data.backgroundColor };
    }
    return {
      width: `${data[keyToBeUsed].width}px`,
      height: `${data[keyToBeUsed].height}px`,
      backgroundColor: data.backgroundColor,
    };
  }
  render() {
    return <div className="nw-mt-paddingcomponent" style={this.currentStyle} />;
  }
}

PaddingComponent.wrappedComponent.propTypes = {
  data: PropTypes.shape({}).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));;

const ProjectComponent = {
  getFinalComponent,
  wrapper: PaddingComponent.wrappedComponent,
  component: PaddingComponent,
};

export default ProjectComponent;
