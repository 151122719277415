import React, { Component } from "react";
import PropTypes from "prop-types";
import { Img } from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
import { imageFileName } from "../../../../utils";
import { BRAND_NAME } from "npmlinks-constants";

@inject("BrandStore")
@observer
class BrandStoryLogo extends Component {
  componentDidMount() {
    if (!this.image) {
      this.props.hideComponent();
    }
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error BrandStoryLogo ${error} ${errorInfo} ${this}`,
    );
  }
  get image() {
    const { data } = this.props;
    const { logoImages = [] } = data;
    const [item = {}] = logoImages;
    const { path = null } = item;
    return (path && `${this.props.BrandStore.urlPrefix}${path}`) || "";
  }

  render() {
    const { className } = this.props;
    return (
      <div className="nw-brandstorylogo-wrapper">
        <Img
          src={this.image}
          className={`nw-brandstorylogo-image ${className}`}
          alt={imageFileName(this.image)}
        />
      </div>
    );
  }
}
BrandStoryLogo.defaultProps = {
  index: 0,
  className: "",
};

BrandStoryLogo.wrappedComponent.propTypes = {
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  hideComponent: PropTypes.func.isRequired,
};
const getFinalComponent = component =>
  inject("BrandStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandStoryLogo.wrappedComponent,
  component: BrandStoryLogo,
};

export default ProjectComponent;
