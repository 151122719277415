import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import Anchor from "../../../Anchor";

@inject("BrandStore")
@observer
class QuickLinksComponent extends Component {
  constructor(props) {
    super(props);
    this.gaClick = this.gaClick.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->QuickLinksComponent", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error QuickLinksComponent ${error} ${errorInfo} ${this}`,
    );
  }
  get title() {
    const { data } = this.props;
    const { title } = data;
    return title && title.hasOwnProperty("text") ? title.text : title;
  }
  gaClick(item, index) {
    const {
      sectionIndex,
      componentIndex,
      data: { identity },
    } = this.props;
    if (this.props.BrandStore.isBT) {
      Analytics.trackEvent({
        category: this.props.BrandStore.isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.BT_QUICK_LINKS,
        ]),
        label: Event.action.getMasterTemplateActions([
          componentIndex,
          item.text,
          index,
          identity,
        ]),
      });
      return null;
    }
    Analytics.trackEvent({
      category: window.pageType,
      action: Event.action.getMasterTemplateActions([
        sectionIndex,
        Event.action.MASTER_TEMPLATE_PAGES.QUICK_LINKS,
      ]),
      label: Event.label.getMasterTemplateLabels([index, item.url, identity]),
    });
    return null;
  }
  render() {
    const { data } = this.props;
    return (
      <div className="nw-quicklinkscomponent">
        {this.title && (
          <h3 className="nw-quicklinkscomponent-title">{this.title}</h3>
        )}
        <ul className="nw-quicklinkscomponent-list">
          {data.list &&
            data.list.map((item, index) => (
              <li className="nw-quicklinkscomponent-item" key={item.url}>
                <Anchor.component
                  to={item.url}
                  onClick={() => this.gaClick(item, index)}
                  className="nw-quicklinkscomponent-anchor"
                  href={item.url}
                  target="_self"
                >
                  {item.text}
                </Anchor.component>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
QuickLinksComponent.propTypes = {
  data: PropTypes.shape({}).isRequired,
  sectionIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: QuickLinksComponent,
  component: QuickLinksComponent,
};

export default ProjectComponent;
