import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Img, Button, Toastr, Ripple } from "arv-reactcomponents";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import { imageFileName } from "../../../../utils";
import { MASTER_TEMPLATE_COMPONENT_CONFIGS } from "npmlinks-constants";

@inject("BrandStore", "WishListStore", "LoginStore", "CommonStore")
@observer
class TopBar extends Component {
  static triggerToastr() {
    Toastr.showToastr({
      className: "nwc-toastr-list-success nw-toastr",
      message: MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.ADD_SUCCESS,
      timeout: MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.TIMEOUT,
    });
  }
  static triggerRemoveToastr() {
    Toastr.showToastr({
      className: "nwc-toastr-list-success nw-toastr",
      message: MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.REMOVE_SUCCESS,
      timeout: MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.TIMEOUT,
    });
  }
  constructor(props) {
    super(props);
    this.triggerToastr = false;
    this.wishlisted = this.wishlisted.bind(this);
    this.init = this.init.bind(this);
    this.favorite = this.favorite.bind(this);
    this.removeFavBrand = this.removeFavBrand.bind(this);
    this.triggerGa = this.triggerGa.bind(this);
    this.checkHide = this.checkHide.bind(this);
  }
  componentDidMount() {
    this.checkHide();
    this.init();
    console.log("ComponentPool->TopBar", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(`ComponentPool->error TopBar ${error} ${errorInfo} ${this}`);
  }
  componentWillReceiveProps(nextProps) {
    const { LoginStore } = nextProps;
    const { isUserLoggedIn } = LoginStore;
    if (isUserLoggedIn) {
      this.init();
    }
  }

  get showLogo() {
    const { templateType } = this.props.BrandStore;
    return MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.SHOW_LOGO.includes(
      templateType,
    )
      ? ""
      : "nwc-hide";
  }
  get data() {
    const { data } = this.props;
    const { value } = data;
    return value || data;
  }
  get image() {
    const { images } = this.data || {};
    const [pathObj] = images || [];
    const { path } = pathObj || {};
    const prefixedPath = path && `${this.props.BrandStore.urlPrefix}${path}`;
    return (
      (prefixedPath && (
        <Img
          className="nw-logotopbar-image"
          src={prefixedPath}
          alt={imageFileName(prefixedPath)}
        />
      )) || <Fragment />
    );
  }
  get isUserLoggedIn() {
    const { isUserLoggedIn } = this.props.LoginStore;
    return isUserLoggedIn;
  }
  get brandId() {
    const { brandId } = this.props.BrandStore;
    return brandId;
  }
  get buttonStyle() {
    const { backgroundColor } = this.data.favouriteButton || {};
    if (backgroundColor) {
      return {
        backgroundColor,
      };
    }
    return {};
  }
  get textStyle() {
    const { foregroundColor } = this.data.favouriteButton || {};
    if (foregroundColor) {
      return {
        color: foregroundColor,
      };
    }
    return {};
  }
  wishlisted() {
    const { brandId } = this.props.BrandStore;
    const { brandWishListData } = this.props.WishListStore;
    const isWishListed =
      brandWishListData.filter(item => item.brandId === brandId).length > 0;
    if (isWishListed && this.triggerToastr) {
      this.triggerToastr = false;
      TopBar.triggerToastr();
      this.triggerGa();
    }
    return isWishListed;
  }
  init() {
    if (this.isUserLoggedIn) {
      this.props.WishListStore.getfavBrand();
    }
  }

  favorite() {
    if (this.wishlisted()) {
      this.removeFavBrand();
    } else if (!this.isUserLoggedIn) {
      this.triggerToastr = true;
      this.props.WishListStore.setTempBrandWishList(this.brandId);
      this.props.LoginStore.setloginModal(true);
    } else {
      this.props.WishListStore.brandWishList(this.brandId).then(() => {
        TopBar.triggerToastr();
        this.triggerGa();
      });
    }
  }
  removeFavBrand() {
    this.props.WishListStore.deleteFavBrand(this.brandId).then(() => {
      TopBar.triggerRemoveToastr();
      this.triggerGa();
    });
  }
  triggerGa() {
    const wishlisted = this.wishlisted();
    const {
      sectionIndex,
      data: { identity },
    } = this.props;
    const action = Event.action.getMasterTemplateActions([
      sectionIndex,
      Event.action.MASTER_TEMPLATE_PAGES.TOP_BAR,
    ]);
    const label = wishlisted
      ? MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.LABELS.FAVORITED
      : MASTER_TEMPLATE_COMPONENT_CONFIGS.TOP_BAR.LABELS.UNFAVORITED;
    Analytics.trackEvent({
      category: window.pageType,
      action,
      label: Event.label.getMasterTemplateLabels([label, identity]),
    });
  }
  checkHide() {
    const { isBrandPage } = this.props.CommonStore;
    const { hideComponent } = this.props;
    if (isBrandPage || this.showLogo) {
      hideComponent();
    }
  }
  get tohide() {
    const { isBrandPage } = this.props.CommonStore;
    return isBrandPage || this.showLogo;
  }
  render() {
    if (this.tohide) {
      return null;
    }
    return (
      <div className="nw-logotopbar">
        <div className="nw-logotopbar-imagecontainer">{this.image}</div>
        <div className={`nw-logotopbar-favcontainer ${this.showLogo}`}>
          <Ripple className="nwc-ripple-light">
            <Button
              className="nw-logotopbar-favblock"
              onClick={this.favorite}
              style={this.buttonStyle}
            >
              <i
                className={`nw-logotopbar-favicon icomoon-heart${
                  this.wishlisted() ? "" : "-o"
                  }`}
                style={this.textStyle}
              />
              <p className="nw-logotopbar-favtext" style={this.textStyle}>
                ADD
                {this.wishlisted() ? "ED" : ""} TO FAVORITES
              </p>
            </Button>
          </Ripple>
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  hideComponent: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  data: PropTypes.shape({}).isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
    brandId: PropTypes.string.isRequired,
    templateType: PropTypes.string.isRequired,
  }).isRequired,
  WishListStore: PropTypes.shape({
    deleteFavBrand: PropTypes.func.isRequired,
    getfavBrand: PropTypes.func.isRequired,
    setTempBrandWishList: PropTypes.func.isRequired,
    brandWishList: PropTypes.func.isRequired,
    brandWishListData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  LoginStore: PropTypes.shape({
    isUserLoggedIn: PropTypes.bool.isRequired,
    setloginModal: PropTypes.func.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "WishListStore", "LoginStore", "CommonStore")(
    observer(component),
  );

const ProjectComponent = {
  getFinalComponent,
  wrapper: TopBar,
  component: TopBar,
};

export default ProjectComponent;
