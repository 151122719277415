import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "arv-reactcomponents";

class TitleSubtitleCta extends PureComponent {
  constructor(props) {
    super(props);
  }
  get titleStyle() {
    const { title: { color } } = this.props;
    return color ? { color } : {};
  }
  get subtitleStyle() {
    const { subtitle: { color } } = this.props;
    return color ? { color } : {};
  }
  render() {
    const {
      ctaText,
      key,
      title: { text: titleText },
      subtitle: { text: subTitleText },
    } = this.props;
    return (
      <div className="nw-titlesubtitlecta-content">
        <h2 className="nw-titlesubtitlecta-title" style={this.titleStyle}>
          {titleText}
        </h2>
        <h4 className="nw-titlesubtitlecta-subtitle" style={this.subtitleStyle}>
          {subTitleText}
        </h4>
        {ctaText && (
          <Button key={key} className="nw-titlesubtitlecta-cta">
            {ctaText}
          </Button>
        )}
      </div>
    );
  }
}

TitleSubtitleCta.defaultProps = {
  key: Math.random(),
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  ctaText: "",
};
TitleSubtitleCta.propTypes = {
  ctaText: PropTypes.string,
  title: PropTypes.shape({}),
  subtitle: PropTypes.shape({}),
  key: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: TitleSubtitleCta,
  component: TitleSubtitleCta,
};

export default ProjectComponent;
