import React, { Component } from "react";
import PropTypes from "prop-types";

class Title extends Component {
  get titleStyle() {
    const { data } = this.props;
    const {
      title: { color, align },
    } = data;
    return { color, textAlign: align };
  }
  get titleType() {
    const { data } = this.props;
    const { titleType } = data;
    return (titleType && `is-${titleType}`) || "is-section";
  }
  render() {
    const { data, className } = this.props;
    const { title, subtitle } = data;
    return (
      <div className={`nw-mt-titlecomponent ${className}`}>
        {title && title.text && (
          <h2
            className={`nw-mt-titlecomponenttitle ${className} ${
              this.titleType
            }`}
            style={this.titleStyle}
          >
            {title.text}
          </h2>
        )}
        {subtitle && subtitle.text && (
          <h4
            className={`nw-mt-titlecomponentsubtitle ${className} ${
              this.titleType
            }`}
            style={{ color: subtitle.color, textAlign: subtitle.align }}
          >
            {subtitle.text}
          </h4>
        )}
      </div>
    );
  }
}

Title.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: Title,
  component: Title,
};

export default ProjectComponent;
