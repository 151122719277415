import React from "react";
import PropTypes from "prop-types";

function GenericTextBand(props) {
  const { data, className } = props;
  const { text, foregroundColor, backgroundColor } = data;
  return (
    <div className={`nw-mt-generictext ${className}`}>
      {text && (
        <div
          className={`nw-mt-generictextcontent ${className}`}
          style={{ color: foregroundColor, backgroundColor }}
        >
          {text}
        </div>
      )}
    </div>
  );
}

GenericTextBand.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: GenericTextBand,
  component: GenericTextBand,
};

export default ProjectComponent;
