import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import {
  MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_DESKTOP,
  MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_MOBILE,
} from "npmlinks-constants";

@inject("CommonStore")
@observer
class TitleDescriptionHorizontal extends Component {
  componentDidMount() {
    console.log("ComponentPool->TitleDescriptionHorizontal", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error TitleDescriptionHorizontal ${error} ${errorInfo} ${this}`,
    );
  }

  get titleText() {
    const { data: { title } = {} } = this.props;
    const { isDesktop } = this.props.CommonStore;

    let titleText =
      (title && title.text) || (typeof title === "object" ? "" : title);
    if (isDesktop) {
      titleText =
        titleText &&
        titleText.length > MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_DESKTOP
          ? `${titleText.substring(
              0,
              MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_DESKTOP,
            )}...`
          : titleText;
    } else {
      titleText =
        titleText &&
        titleText.length > MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_MOBILE
          ? `${titleText.substring(
              0,
              MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_MOBILE,
            )}...`
          : titleText;
    }
    return titleText;
  }

  render() {
    const { data: { description } = {} } = this.props;
    return (
      <div className="nw-grid-row nw-title-description-container">
        <h3 className="nwc-grid-col-xs-4 nw-title-border">{this.titleText}</h3>
        <div className="nw-cmt-mobiletitleborder nw-mobile-only" />
        <div className="nwc-grid-col-xs-8 nw-block-description">
          {(description && description.text) || description}
        </div>
      </div>
    );
  }
}

TitleDescriptionHorizontal.wrappedComponent.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: TitleDescriptionHorizontal.wrappedComponent,
  component: TitleDescriptionHorizontal,
};

export default ProjectComponent;
