import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { LazyImg } from "arv-reactcomponents";
import LazyLoad from 'react-lazyload';
import { isIframe, imageFileName } from "../../../../utils";

@inject("BrandStore", "CommonStore")
@observer
class LogoBand extends Component {
  constructor() {
    super();
    this.state = {
      hidden: [],
    };
    this.getImage = this.getImage.bind(this);
    this.onError = this.onError.bind(this);
  }
  get title() {
    const { data } = this.props;
    return (data && data.title) || "";
  }
  getImage(item) {
    const { path: src } = item;
    return (src && `${this.props.BrandStore.urlPrefix}${src}`) || "";
  }
  onError(index) {
    const {
      data: { images },
    } = this.props;
    const { hidden } = this.state;
    hidden.push(index);
    this.setState({ hidden });
    const anyValidPresent = images.filter(
      (item, itemIndex) => !hidden.includes(itemIndex),
    );
    if (anyValidPresent.length === 0) {
      this.props.hideComponent();
    }
  }
  render() {
    const { className, data } = this.props;
    const { isDesktop } = this.props.CommonStore;

    return (
      <div className={`nw-mt-logoband ${className}`}>
        {this.title && (
          <h3 className={`nw-mt-logobandtitle ${className}`}>{this.title}</h3>
        )}
        {data &&
          data.images &&
          data.images.map((item, index) => (
            <LazyLoad>
            <LazyImg
              src={this.getImage(item)}
              alt={imageFileName(this.getImage(item))}
              index={index}
              onWinLoad={!isDesktop || isIframe}
              className={`nw-mt-logobandimage ${className}`}
              rootMargin={`1500px`}
              onError={() => this.onError(index)}
            />
            </LazyLoad>
          ))}
      </div>
    );
  }
}

LogoBand.wrappedComponent.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  hideComponent: PropTypes.func.isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LogoBand.wrappedComponent,
  component: LogoBand,
};

export default ProjectComponent;
