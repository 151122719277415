import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Img, Modal } from "arv-reactcomponents";
import Anchor from "../../../Anchor";
import {
  deduceValuesFromPipedData,
  NavMethods,
  imageFileName,
} from "../../../../utils";
import LeftNavModal from "./LeftNavModal";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";

@inject("BrandStore", "CommonStore")
@observer
class LeftNavMobile extends Component {
  static aspectFit(item) {
    const { image } = item;
    return image.width <= image.height ? "is-fullwidth" : "is-fullheight";
  }
  static getUrl(item) {
    const { pcmData, externalURL = "" } = item;
    const { url } = deduceValuesFromPipedData(pcmData);
    return url || externalURL || "";
  }
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalData: [],
      hiddenIndices: [],
    };
    this.getSrc = this.getSrc.bind(this);
    this.onClick = this.onClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.triggerGa = this.triggerGa.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->LeftNavMobile", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error LeftNavMobile ${error} ${errorInfo} ${this}`,
    );
  }
  imageError(index) {
    const { hiddenIndices } = this.state;
    hiddenIndices.push(index);
    this.setState({ hiddenIndices });
  }
  onClick(event, item, index) {
    this.triggerGa(item, index);
    if (item && item.children && item.children.length) {
      event.preventDefault();
      this.setState({ modalOpen: true, modalData: item.children });
    }
  }
  get title() {
    const { data } = this.props;
    const { value, title } = data;
    const titleFromTitle =
      (title && title.hasOwnProperty("text") && title.text) || title || "";
    const titleFromValue = (value && value.title) || "";
    return titleFromTitle || titleFromValue;
  }
  get list() {
    const { data } = this.props;
    const { navTree, rootKeys } = data || {};
    const { isDesktop } = this.props.CommonStore;
    const processedList =
      NavMethods.processLeftNav(navTree, rootKeys, isDesktop) || [];
    const list = (data && data.value && data.value.list) || [];

    const finalList =
      (list.length && list) || (processedList.length && processedList) || [];
    const returnLIst = finalList.filter(
      (item, index) =>
        !this.state.hiddenIndices.includes(index) &&
        item &&
        item.image &&
        item.image.path,
    );
    return returnLIst;
  }
  getSrc(item) {
    const path = item && item.image && item.image.path;
    return `${this.props.BrandStore.urlPrefix}${path}` || "";
  }
  closeModal() {
    this.setState({ modalOpen: false, modalData: [] });
  }
  triggerGa(item, index) {
    const {
      sectionIndex,
      componentIndex,
      data: { identity },
    } = this.props;
    if (this.props.BrandStore.isBT) {
      Analytics.trackEvent({
        category: this.props.BrandStore.isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.BT_MNAV_CLICK,
        ]),
        label: Event.label.getMasterTemplateLabels([
          componentIndex,
          item.title,
          index,
          identity,
        ]),
      });
      return null;
    }
    const action = Event.action.getMasterTemplateActions([
      sectionIndex,
      Event.action.MASTER_TEMPLATE_PAGES.LEFT_NAV_MOBILE,
    ]);
    const labelArray = [index, LeftNavMobile.getUrl(item)];
    if (identity) {
      labelArray.push(identity);
    }
    const label = Event.label.getMasterTemplateLabels(labelArray);
    Analytics.trackEvent({
      category: window.pageType,
      action,
      label,
    });
    return null;
  }
  render() {
    const { modalOpen, modalData } = this.state;
    return (
      <div className="nw-leftnavmobile">
        {this.title && <h3 className="nw-leftnavmobile-title">{this.title}</h3>}
        <div
          className={`nw-leftnavmobile-list ${
            this.list.length < 4 ? "is-noscroll" : ""
            }`}
        >
          {this.list &&
            this.list.map((item, index) => (
              <div>
                <Anchor.component
                  to={LeftNavMobile.getUrl(item)}
                  href={LeftNavMobile.getUrl(item)}
                  className="nw-leftnavmobile-item"
                  onClick={event => this.onClick(event, item, index)}
                  target="_self"
                >
                  <Img
                    className={`nw-leftnavmobile-itemimage ${LeftNavMobile.aspectFit(
                      item,
                    )}`}
                    src={this.getSrc(item)}
                    alt={imageFileName(this.getSrc(item))}
                    onError={() => this.imageError(index)}
                  />
                </Anchor.component>
                {item.title && (
                  <p className="nw-leftnavmobile-itemtitle">{item.title}</p>
                )}
              </div>
            ))}
        </div>
        <Modal
          isOpen={modalOpen}
          onClose={this.closeModal}
          className="nw-leftnavmobile-modal"
        >
          <LeftNavModal.component data={modalData} index={0} />
        </Modal>
      </div>
    );
  }
}

LeftNavMobile.wrappedComponent.propTypes = {
  data: PropTypes.shape({}).isRequired,
  componentIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
    isBT: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

export { default as LeftNavModal } from "./LeftNavModal";

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LeftNavMobile.wrappedComponent,
  component: LeftNavMobile,
};

export default ProjectComponent;
