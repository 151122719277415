import React, { Component, Fragment } from "react";
import { GridRow, GridColumn, Masonry } from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import StoryComponent from "../StoryComponent";

@inject("CommonStore", "BrandStore")
@observer
class StoryGrid extends Component {
  constructor() {
    super();
    this.renderList = this.renderList.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->StoryGrid", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error StoryGrid ${error} ${errorInfo} ${this}`,
    );
  }
  get layout() {
    const { data } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const key = (isDesktop && "desktop") || "mobile-site";
    const { column = 1 } = data[key] || {};
    const rows = Math.ceil(data.children.length / parseInt(column, 10));
    return {
      base: rows * column,
      columnCount: { sm: rows, xs: rows },
    };
  }
  get currentLayoutStyle() {
    const { data } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const key = (isDesktop && "desktop") || "mobile-site";
    const value =
      (data[key] && data[key].column && parseInt(data[key].column, 10)) || 1;
    const columnValue = Math.floor(100 / value);
    return {
      flex: `0 0 ${columnValue}%`,
      maxWidth: `${columnValue}%`,
    };
  }
  get childStories() {
    const { data } = this.props;
    return (data && data.children) || [];
  }
  get title() {
    const { data } = this.props;
    return (data && data.title && data.title.text) || "";
  }
  renderList(item) {
    const { sectionIndex } = this.props;
    const { brandJourneyId } = this.props.CommonStore;
    const { templateClass } = this.props.BrandStore;
    const classNameNew = ` nw-mt-${brandJourneyId} ${templateClass}`;
    return (
      <StoryComponent.component
        className={classNameNew}
        data={item}
        key={item.title.text}
        sectionIndex={sectionIndex}
        componentIndex={item.index}
      />
    );
  }

  render() {
    const { className } = this.props;
    const { isDesktop } = this.props.CommonStore;

    if (global && global.isServer && !isDesktop) {
      return null;
    }
    return (
      <Fragment>
        {this.title && (
          <GridRow className={`nw-storygrid-titlerow ${className}`}>
            <GridColumn className={`nw-storygrid-titlecol ${className}`}>
              <h3 className={`nw-storygrid-title ${className}`}>
                {this.title}
              </h3>
            </GridColumn>
          </GridRow>
        )}
        <GridRow className={`nw-storygridcontainer ${className}`}>
          <Masonry
            className={`nw-storygridcolumn ${className}`}
            data={this.childStories}
            columnCount={this.layout.columnCount}
            base={this.layout.base}
            renderList={this.renderList}
            style={this.currentLayoutStyle}
          />
        </GridRow>
      </Fragment>
    );
  }
}

StoryGrid.wrappedComponent.defaultProps = {
  className: "",
  sectionIndex: 0,
};

StoryGrid.wrappedComponent.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  sectionIndex: PropTypes.number,
  BrandStore: PropTypes.shape({
    templateClass: PropTypes.string,
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandJourneyId: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: StoryGrid.wrappedComponent,
  component: StoryGrid,
};

export default ProjectComponent;
