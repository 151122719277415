import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { imageFileName } from "../../../../utils";
import { Img } from "arv-reactcomponents";

@inject("BrandStore")
@observer
class BlogHeading extends Component {
  get bloggerImage() {
    const { data } = this.props;
    const { bloggerImage } = data;
    const path =
      (bloggerImage && bloggerImage[0] && bloggerImage[0].path) || null;
    return (path && `${this.props.BrandStore.urlPrefix}${path}`) || "";
  }
  get bloggerName() {
    const { data } = this.props;
    return (data && data.bloggerName) || {};
  }
  get date() {
    const { data } = this.props;
    return (data && data.date) || {};
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`nw-mt-blogheading ${className}`}>
        {this.bloggerImage && (
          <div className={`nw-mt-blogheadingimagecontainer ${className}`}>
            <Img
              className={`nw-mt-blogheadingimage ${className}`}
              src={this.bloggerImage}
              alt={imageFileName(this.bloggerImage)}
            />
          </div>
        )}
        {this.bloggerName.text && (
          <span
            className={`nw-mt-blogheadingname ${className}`}
            style={
              (this.bloggerName.color && { color: this.bloggerName.color }) ||
              {}
            }
          >
            {this.bloggerName.text}
          </span>
        )}
        {this.date.text && (
          <span
            className={`nw-mt-blogheadingdate ${className}`}
            style={
              (this.date.color && {
                color: this.date.color,
                borderColor: this.date.color,
              }) ||
              {}
            }
          >
            {this.date.text}
          </span>
        )}
      </div>
    );
  }
}

BlogHeading.defaultProps = {
  className: "",
};

BlogHeading.wrappedComponent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: BlogHeading.wrappedComponent,
  component: BlogHeading,
};

export default ProjectComponent;
