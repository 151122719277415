import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { GridContainer, GridRow, GridColumn } from "arv-reactcomponents";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  PDP_CAROUSEL_SIZE,
  PDP_CAROUSEL_DEFAULT_VIEWALL_TEXT,
  MASTERTEMPLATE,
  pageType
} from "npmlinks-constants";

import CarouselComponent from "./CarouselComponent";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
import Anchor from "../Anchor";
import PdpFavoriteComponentV2 from "../Favorite";

@inject("ProductStore", "BrandStore", "CommonStore")
@observer
class ProductCarousel extends Component {
  constructor() {
    super();
    this.clearProductData = this.clearProductData.bind(this);
    this.triggerGa = this.triggerGa.bind(this);
  }
  clearProductData() {
    window.scrollTo(0, 0);
    this.props.ProductStore.clearProductData();
    this.triggerGa();
  }
  triggerGa() {
    const {
      origin,
      title,
      identity,
      sectionIndex,
      componentIndex
    } = this.props;
    if (window.pageType === MASTERTEMPLATE.BT) {
      Analytics.trackEvent({
        category: this.props.BrandStore.isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.BT_PCAR_VALL
        ]),
        label: Event.label.getMasterTemplateLabels([componentIndex, identity])
      });
      return null;
    }
    if (window.pageType === pageType.pdp) {
      Analytics.trackEvent({
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
        action: Event.action.getMasterTemplateActions([
          origin,
          Event.action.PRODUCT_CAROUSEL_VIEW_ALL
        ]),
        label: Event.label.getMasterTemplateLabels([
          Event.label.getProperLabelWithoutSpaces(title),
          identity
        ])
      });
      return null;
    }
    Analytics.trackEvent({
      category: window.pageType,
      action: Event.action.getMasterTemplateActions([
        origin,
        Event.action.PRODUCT_CAROUSEL_VIEW_ALL
      ]),
      label: Event.label.getMasterTemplateLabels([
        Event.label.getProperLabelWithoutSpaces(title),
        identity
      ])
    });
    return null;
  }
  render() {
    const {
      title,
      list,
      viewAllLink,
      size,
      viewAllText,
      origin,
      className,
      mobileViewAllReload,
      titleColor,
      subtitle,
      viewAll,
      shopAll,
      identity,
      sectionIndex,
      componentIndex
    } = this.props;
    const { isDesktop } = this.props.CommonStore;
    return (
      <Fragment>
        {list.length > 0 && (
          <GridContainer className={`nw-pdpcarousel ${className}`}>
            <GridRow className={`nw-pdpcarouseltitlecontainer ${className}`}>
              <div className={`nwc-grid-col nwc-grid-col-sm-3 ${className}`} />
              <GridColumn
                className={`nwc-grid-col-sm-6 nw-pdpcarouseltitlecolumn ${className}`}
              >
                <h3
                  style={{ color: titleColor }}
                  className={`nw-pdpcarouseltitle ${className}`}
                >
                  {title}
                </h3>
                {subtitle && subtitle.text && (
                  <div
                    style={
                      (subtitle &&
                        subtitle.color && { color: subtitle.color }) ||
                      {}
                    }
                    className={`nw-pdpcarouselsubtitle ${className}`}
                  >
                    {subtitle.text}
                  </div>
                )}
              </GridColumn>
              <GridColumn
                className={`nwc-grid-col-sm-${(title && 3) ||
                  12} nw-pdpcarouselviewall ${className}`}
              >
                {(viewAllLink || (viewAll && viewAll.url)) && (
                  <Anchor.component
                    href={(viewAll && viewAll.url) || viewAllLink}
                    to={(viewAll && viewAll.url) || viewAllLink}
                    target="_blank"
                    className={`nw-pdpcarouselviewallanchor ${className}`}
                    onClick={this.triggerGa}
                    style={
                      (viewAll && viewAll.color && { color: viewAll.color }) ||
                      {}
                    }
                  >
                    {(viewAll && viewAll.text) || viewAllText}
                    <i
                      className={`icomoon-arrow_right nw-pdpcarouselviewallanchorarrow ${className}`}
                      style={
                        (viewAll &&
                          viewAll.color && { color: viewAll.color }) ||
                        {}
                      }
                    />
                  </Anchor.component>
                )}
              </GridColumn>
            </GridRow>

            <GridRow
              className={`nw-pdpcarousel-carouselcontainer ${className}`}
            >
              <GridColumn>
                <CarouselComponent.component
                  title={title}
                  list={list}
                  size={size}
                  origin={origin}
                  className={className}
                  identity={identity}
                  sectionIndex={sectionIndex}
                  componentIndex={componentIndex}
                />
              </GridColumn>
            </GridRow>
            {(viewAllLink || (viewAll && viewAll.url)) && !shopAll && (
              <GridRow
                className={`nw-pdpcarousel-mobileviewallcontainer ${className}`}
              >
                <GridColumn
                  className={`nw-pdpcarouselmobileviewall ${className}`}
                >
                  <Anchor.component
                    href={(viewAll && viewAll.url) || viewAllLink}
                    to={(viewAll && viewAll.url) || viewAllLink}
                    target={`${mobileViewAllReload ? "_self" : ""}`}
                    className={`nw-pdpcarouselmobileviewallbutton ${className}`}
                    onClick={this.clearProductData}
                    style={
                      (viewAll && viewAll.color && { color: viewAll.color }) ||
                      {}
                    }
                  >
                    {(viewAll && viewAll.text) || viewAllText}
                  </Anchor.component>
                </GridColumn>
              </GridRow>
            )}
          </GridContainer>
        )}
      </Fragment>
    );
  }
}

ProductCarousel.wrappedComponent.defaultProps = {
  shopAll: false,
  viewAllLink: "",
  size: PDP_CAROUSEL_SIZE,
  viewAllText: PDP_CAROUSEL_DEFAULT_VIEWALL_TEXT,
  origin: "",
  className: "",
  mobileViewAllReload: false,
  subtitle: {},
  titleColor: null,
  viewAll: null,
  title: "",
  identity: null
};

ProductCarousel.wrappedComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired,
  shopAll: PropTypes.bool,
  mobileViewAllReload: PropTypes.bool,
  className: PropTypes.string,
  ProductStore: PropTypes.shape({
    clearProductData: PropTypes.func.isRequired
  }).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  subtitle: PropTypes.shape({}),
  viewAllLink: PropTypes.string,
  viewAllText: PropTypes.string,
  size: PropTypes.shape({}),
  origin: PropTypes.string,
  viewAll: PropTypes.shape({}),
  identity: PropTypes.string,
  BrandStore: PropTypes.shape({ isBT: PropTypes.string.isRequired }).isRequired
};
export { default as CarouselComponent } from "./CarouselComponent";

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductCarousel.wrappedComponent,
  component: ProductCarousel
};

export default ProjectComponent;
