import React, { Component } from "react";
import PropTypes from "prop-types";

class ParaText extends Component {
  get currentStyle() {
    const { data } = this.props;
    const { color, align } = data;
    return { color, textAlign: align };
  }
  render() {
    const { data, className } = this.props;
    const { title = "", html } = data;
    return (
      <div className={`nw-mt-paratext ${className}`}>
        {title && (
          <h3 className={`nw-mt-paratexttitle ${className}`}>{title}</h3>
        )}
        <div
          className={`nw-mt-paratextcontent ${className}`}
          dangerouslySetInnerHTML={{ __html: html }}
          style={this.currentStyle}
        />
      </div>
    );
  }
}

ParaText.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ParaText,
  component: ParaText,
};

export default ProjectComponent;
