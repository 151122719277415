import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  MASTER_TEMPLATE_COMPONENT_CONFIGS,
  PDP_CAROUSEL_DEFAULT_VIEWALL_TEXT,
  IFRAME_LISTING_PARAMS,
} from "npmlinks-constants";
import ProductCarousel from "../../../ProductCarousel";
import {
  deduceValuesFromPipedData,
  getBackgroundColorCode,
  isIframe,
} from "../../../../utils";
import Event from "../../../../analytics/eventFactory";
import { ShopMultiple } from "../../../";

@inject("BrandStore", "CommonStore")
@observer
class ProductCarouselComponent extends Component {
  static getUtl() {
    return "";
  }
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      size: MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.SIZES,
      callToAction: "",
    };
    this.init = this.init.bind(this);
    this.setSize = this.setSize.bind(this);
  }
  componentDidMount() {
    const { data } = this.props;
    this.init(data);
    console.log("ComponentPool->ProductCarouselComponent", this.props);
  }

  componentDidCatch(error, errorInfo) {
    this.props.hideComponent();
    console.error(
      `ComponentPool->error ProductCarouselComponent ${error} ${errorInfo}`,
    );
  }
  get currentClassName() {
    const { brandJourneyId } = this.props.CommonStore;
    const { templateClass } = this.props.BrandStore;
    return `nw-mt-${brandJourneyId} ${templateClass}`;
  }
  get loaderBackground() {
    const { list } = this.state;
    return list && list.length > 0
      ? {}
      : { backgroundColor: getBackgroundColorCode() };
  }
  get currentUrl() {
    const { list } = this.state;
    if (
      list.length < MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.API_LIMIT
    ) {
      return "";
    }
    const { data = {} } = this.props;
    const { params, storyType = {} } = data;
    const { pcmData = "", type } = storyType;
    const { id, url } = deduceValuesFromPipedData(pcmData);
    switch (type) {
      case "newArrivals":
        return "/products?na&sort=newest";
      case "onSale":
        return "/products?os";
      case "collection":
        return `/products?cid=${id}${params || ""}`;
      default:
        return url;
    }
  }
  get origin() {
    const { sectionIndex } = this.props;
    return Event.action.getMasterTemplateActions([
      sectionIndex,
      Event.action.MASTER_TEMPLATE_PAGES.PRODUCT_CAROUSEL,
    ]);
  }
  get styleIdList() {
    const { list } = this.state;
    return (
      (list &&
        list
          .map(item => (item && item.styleId) || item.id)
          .filter(item => item)) ||
      []
    );
  }
  setSize(ref) {
    // const parentWidth =
    //   (document.querySelector(".nw-mastertemplatepage") &&
    //     document.querySelector(".nw-mastertemplatepage").offsetWidth) ||
    //   0;
    const width = (ref && ref.offsetWidth) || 1;
    const ratio = 1230 / width;
    const size = {
      XS: Math.ceil(
        MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.SIZES.XS / ratio,
      ),
      SM: Math.ceil(
        MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.SIZES.SM / ratio,
      ),
      MD: Math.ceil(
        MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.SIZES.MD / ratio,
      ),
      LG:
        Math.ceil(
          MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.SIZES.LG / ratio,
        ) > 2
          ? Math.ceil(
              MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.SIZES.LG /
                ratio,
            )
          : 1,
    };
    this.setState({ size });
  }
  init(data) {
    const { params } = data;
    const { type, pcmData } = data.storyType;
    const { id } = deduceValuesFromPipedData(pcmData);
    const callToAction =
      (data && data.callToAction) || PDP_CAROUSEL_DEFAULT_VIEWALL_TEXT;
    const base = "listing";
    const extension = "products";
    let body;

    switch (type) {
      case "collection":
        body = {
          deeplinkurl: `/products?cid=${id}&v=${
            MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.API_LIMIT
          }${params || ""}`,
        };
        break;
      case "onSale":
        body = {
          deeplinkurl: `/products?os&v=${
            MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.API_LIMIT
          }${params || ""}`,
        };
        break;
      default:
        body = {
          deeplinkurl: `/products?na&sort=newest&v=${
            MASTER_TEMPLATE_COMPONENT_CONFIGS.PRODUCT_CAROUSEL.API_LIMIT
          }${params || ""}`,
        };
        break;
    }
    const { brandDetails } = this.props.CommonStore;
    const parentBrand =
      brandDetails &&
      brandDetails.parentBrandName &&
      brandDetails.parentBrandName[0];
    const legalBrandNames =
      (brandDetails && brandDetails.legalBrandNames) || [];
    if (parentBrand) {
      body.brandDetails = {
        isBrandJourney: true,
        legalBrandNames,
        parentBrand,
      };
    }
    if (isIframe && body.brandDetails) {
      body.brandDetails = Object.assign(
        {},
        body.brandDetails,
        IFRAME_LISTING_PARAMS,
      );
    }
    this.props.BrandStore.getCarouselData(
      base,
      extension,
      JSON.stringify(body),
    ).then(
      response => {
        const listingResponse =
          (response &&
            response.data &&
            response.data.styles &&
            response.data.styles.styleList) ||
          [];
        const list = (listingResponse.length && listingResponse) || [];
        if (list && list.length === 0) {
          this.props.hideComponent();
        }
        this.setState({ list, callToAction });
      },
      () => {
        this.props.hideComponent();
      },
    );
  }
  render() {
    const { list, size, callToAction } = this.state;
    const { data, sectionIndex, componentIndex } = this.props;
    const {
      shopAllTitle,
      shopAllButtonText,
      shopAll,
      subtitle,
      viewAll,
      identity,
    } = data;
    const { title } = data;
    const { text, color } = title || {};
    return (
      <div
        className={`nw-productcarouselcomponent ${this.currentClassName}`}
        ref={this.setSize}
        style={this.loaderBackground}
      >
        {list && list.length > 0 && (
          <ProductCarousel.component
            title={text}
            titleColor={color}
            list={list}
            viewAllLink={this.currentUrl}
            origin={this.origin}
            size={size}
            className={this.currentClassName}
            viewAllText={callToAction}
            mobileViewAllReload
            subtitle={subtitle}
            viewAll={viewAll}
            shopAll={shopAll}
            identity={identity}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
          />
        )}
        {list && list.length > 0 && shopAll && (
          <ShopMultiple.default.component
            styleIdList={this.styleIdList}
            title={shopAllTitle || { text }}
            buttonText={shopAllButtonText}
            identity={identity}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
          />
        )}
      </div>
    );
  }
}

ProductCarouselComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  hideComponent: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.shape({
      color: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    storyType: PropTypes.shape({}).isRequired,
  }).isRequired,
  BrandStore: PropTypes.shape({
    templateClass: PropTypes.string,
    getCarouselData: PropTypes.func.isRequired,
    brandId: PropTypes.string.isRequired,
    legalBrandNames: PropTypes.shape([]).isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandJourneyId: PropTypes.string.isRequired,
    brandDetails: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductCarouselComponent,
  component: ProductCarouselComponent,
};

export default ProjectComponent;

/**
 * is Product caraousel is tightly bound with this structure children Object
 *  {
            "children": [
                {
                    "width": "12",
                    "type": "productCarousal",
                    "title": {
                        "color": null,
                        "text": "New Arrivals"
                    },
                    "storyType": {
                        "collection": "100517-CL-SEPHORA-Makeup",
                        "type": "collection",
                        "pcmData": "Collection||100517-CL-SEPHORA-Makeup||/sephora-makeup||||sephora make up, becca make up, make up forever make up, stilla make up||Shop from the best of makeup products at NNNOW. Elevate your beauty routine with cosmetics from Sephora Collection, Make up Forever, Becca, Benefit Cosmetics and More in India.||Sephora.NNNOW.com| Best of Make up From Sephora Online in India"
                    },
                    "dPos": 1
                }
            ],
            "carousalEnabled": false,
            "width": 12,
            "type": "section",
            "dPos": 0
        },
 */
