import React from "react";
import PropTypes from "prop-types";

function BrandStoryTitle(props) {
  const { data: { title }, className } = props;
  return (
    <div className={`nw-brandstorytitlecontainer ${className}`}>
      <h3 className={`nw-brandstorytitle-title ${className}`}>{title.text}</h3>
    </div>
  );
}

BrandStoryTitle.defaultProps = {
  className: "",
};
BrandStoryTitle.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.shape({}).isRequired,
  }).isRequired,
  className: PropTypes.string,
};
const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandStoryTitle,
  component: BrandStoryTitle,
};

export default ProjectComponent;
