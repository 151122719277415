import React from "react";

function DummyComponent() {
  return <div />;
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: DummyComponent,
  component: DummyComponent,
};

export default ProjectComponent;
