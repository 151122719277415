import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { GridRow, GridColumn, Img } from "arv-reactcomponents";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  BRAND_SPECIFIC_CONFIGS,
  MASTERTEMPLATE,
  Endpoints
} from "npmlinks-constants";
import ComponentDecider from "../ComponentDecider";

@inject("BrandStore", "CommonStore")
@observer
class MasterComponent extends Component {
  static noPaddingComponents(isDesktop, data) {
    const { type, key } = data;
    const finalType = type || key;
    return (
      !isDesktop &&
      MASTERTEMPLATE.MANDAROTY_FULLWIDTH_COMPONENTS.includes(finalType)
    );
  }
  static marginClasses(isDesktop, index, data, parentData, elemType) {
    const widthTaken =
      parentData &&
      parentData
        .map((item, dataIndex) => {
          if (dataIndex <= index) {
            return (item && item.width && parseInt(item.width, 10)) || 12;
          }
          return 0;
        })
        .reduce((total, x) => total + x);
    const nextItem = (parentData && parentData[index + 1]) || null;
    const nextItemWidth = nextItem
      ? (nextItem && nextItem.width && parseInt(nextItem.width, 10)) || 12
      : 0;
    const spaceTaken =
      // eslint-disable-next-line no-nested-ternary
      widthTaken > 12
        ? widthTaken % 12 === 0
          ? 12
          : widthTaken % 12
        : widthTaken;
    const isNextItemInSameLevel = 12 - spaceTaken >= nextItemWidth && isDesktop;
    if (isNextItemInSameLevel || index === parentData.length - 1) {
      return "";
    }

    const isSection = type =>
      type && MASTERTEMPLATE.sectionTypes.includes(type);
    const itemType = (data && (data.type || data.key)) || "";
    const nextItemType = (nextItem && (nextItem.type || nextItem.key)) || "";
    if (
      MASTERTEMPLATE.NOPADDING_COMPONENTS.includes(itemType) ||
      MASTERTEMPLATE.NOPADDING_COMPONENTS.includes(nextItemType)
    ) {
      return "nw-mt-nomargincase";
    } else if (
      isSection(itemType) &&
      isSection(nextItemType) &&
      elemType === "row"
    ) {
      return "nw-mt-sectionnextsection";
    } else if (
      isSection(itemType) &&
      nextItemType &&
      !isSection(nextItemType) &&
      elemType === "row"
    ) {
      return "nw-mt-sectionnextcomponent";
    } else if (itemType && !isSection(itemType) && isSection(nextItemType)) {
      return "nw-mt-componentnextsection";
    } else if (itemType && nextItemType) {
      return "nw-mt-componentnextcomponent";
    }
    return "";
  }
  static showStroke(data, id) {
    const { type, key, sectionTitle } = data;
    const finalType = type || key;
    return (
      sectionTitle &&
      finalType &&
      finalType.toLowerCase() === "secondarystorysection" &&
      BRAND_SPECIFIC_CONFIGS &&
      BRAND_SPECIFIC_CONFIGS[id] &&
      BRAND_SPECIFIC_CONFIGS[id].includes("showMasterTemplateBrushStroke")
    );
  }
  static getSubComponentLayout(data, mainData, CommonStore, BrandStore) {
    const width = data.width || 12;
    const val =
      (mainData && mainData.type && mainData.type.toLowerCase()) || "";
    const { brandJourneyId } = CommonStore || {};
    const typeClass = `nw-mt-${val}`;
    const idClass = `nw-mt-${brandJourneyId}`;
    const { templateClass } = BrandStore || {};
    return `nwc-grid-col-sm-${width}  ${typeClass} ${idClass} ${templateClass}`;
  }
  constructor(props) {
    super(props);
    this.state = {
      hiddenComponents: []
    };
    this.componentType = this.componentType.bind(this);
    this.shouldComponentBeShown = this.shouldComponentBeShown.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
    this.getMasterComponentLayout = this.getMasterComponentLayout.bind(this);
  }
  componentDidCatch(error, errorInfo) {
    console.log("master componentError", error, errorInfo, this);
  }
  get componentAtSectionLevel() {
    const { type, key, carousalEnabled } = this.sortedData;
    const finalType = type || key;
    return (
      carousalEnabled ||
      MASTERTEMPLATE.COMPONENTS_AT_SECTION_LEVEL.includes(finalType)
    );
  }
  get sectionTitle() {
    const data = this.sortedData;
    return (
      (data && data.type !== "storyGrid" && data.sectionTitle) ||
      (data && data.type !== "storyGrid" && data.title && data.title.text) ||
      ""
    );
  }
  get sectionTitleStyle() {
    const data = this.sortedData;
    return (
      (data && data.title && data.title.color && { color: data.title.color }) ||
      {}
    );
  }
  get sectionSubtitle() {
    const data = this.sortedData;
    return (
      (data && data.sectionSubtitle) ||
      (data && data.subtitle && data.subtitle.text) ||
      ""
    );
  }
  get sectionSubtitleStyle() {
    const data = this.sortedData;
    return (
      (data &&
        data.subtitle &&
        data.subtitle.color && { color: data.subtitle.color }) ||
      {}
    );
  }
  get sortedData() {
    const { data } = this.props;
    return data;
  }
  getMasterComponentLayout() {
    const { level, data } = this.props;
    const { hiddenComponents } = this.state;
    const val = (data && data.type && data.type.toLowerCase()) || "";
    const hasChildren = (data && data.children && data.children.length) || 0;
    const allChildrenAreNotValid = hiddenComponents.length === hasChildren;
    const { isBrandPage } = this.props.CommonStore || {};
    const sectionClass = !isBrandPage ? `nw-section${data.sectionIndex}` : "";
    const { brandJourneyId } = this.props.CommonStore || {};
    const { templateClass } = this.props.BrandStore || {};
    const widthClass =
      (data.fullWidth === true && "is-fullwidth") ||
      (data.fullWidth === false && "is-restricted") ||
      "";
    return `${
      !allChildrenAreNotValid ? "" : "nwc-hide"
    } nw-mt-${val} nw-mt-${brandJourneyId} ${sectionClass} ${templateClass} ${widthClass} is-level${level}`;
  }
  componentType(data, parentData, index) {
    const { type, key } = data;
    const { level } = this.props;
    const { isDesktop } = this.props.CommonStore;

    if (type === "section" || key === "section") {
      return (
        <MasterComponent
          data={data}
          level={level + 1}
          parentData={parentData.children}
          index={index}
        />
      );
    }
    const className =
      (((level === 0 && parseInt(data.width, 10) === 12 && isDesktop) ||
        MasterComponent.noPaddingComponents(isDesktop, data)) &&
        "nw-mt-nopadding") ||
      (!MASTERTEMPLATE.NOPADDING_COMPONENTS.includes(data.type) &&
        "nw-mt-component") ||
      "";
    return (
      <div className={className}>
        <ComponentDecider.component
          componentData={data}
          parentData={parentData}
          hideComponent={() => this.hideComponent(index)}
        />
      </div>
    );
  }
  shouldComponentBeShown(index) {
    return this.state.hiddenComponents.includes(index) ? "nwc-hide" : "";
  }
  hideComponent(index) {
    const { hiddenComponents } = this.state;
    hiddenComponents.push(index);
    this.setState({ hiddenComponents });
  }
  render() {
    const {
      BrandStore,
      CommonStore,
      parentData,
      data: { sectionIndex },
      index: iterindex
    } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const sectionClassName = this.getMasterComponentLayout();
    if (this.sortedData.children.length === 0) {
      return null;
    }
    return (
      <GridRow
        className={` nw-mastertemplate-sectioncomponent ${sectionClassName} ${MasterComponent.marginClasses(
          isDesktop,
          iterindex,
          this.sortedData,
          parentData,
          "row"
        )}`}
        style={{ backgroundColor: this.sortedData.backgroundColor }}
      >
        {this.sectionTitle && (
          <GridColumn
            className={`nw-mastertemplate-sectioncomponent-titlecontainer  ${sectionClassName}`}
          >
            <h2
              className={`nw-mastertemplate-sectioncomponent-title  ${sectionClassName}`}
              style={this.sectionTitleStyle}
            >
              {this.sectionTitle}
            </h2>
            {MasterComponent.showStroke(
              this.sortedData,
              BrandStore && BrandStore.brandId
            ) && (
              <Img
                className="nw-mastertemplate-brushstroke"
                src={Endpoints.brush_stroke}
              />
            )}
          </GridColumn>
        )}
        {this.sectionSubtitle && (
          <GridColumn className="nw-mastertemplate-sectioncomponent-subtitlecontainer">
            <p
              className="nw-mastertemplate-sectioncomponent-subtitle"
              style={this.sectionSubtitleStyle}
            >
              {this.sectionSubtitle}
            </p>
          </GridColumn>
        )}
        {!this.componentAtSectionLevel &&
          this.sortedData &&
          this.sortedData.children.map((componentData, index) => (
            <GridColumn
              className={`nw-mastertemplate-subcomponent ${MasterComponent.getSubComponentLayout(
                componentData,
                this.sortedData,
                CommonStore,
                BrandStore
              )} ${this.shouldComponentBeShown(
                index
              )} ${MasterComponent.marginClasses(
                isDesktop,
                index,
                componentData,
                this.sortedData.children
              )}`}
              key={`${componentData.type}_${sectionIndex}`}
            >
              {this.componentType(componentData, this.sortedData, index)}
            </GridColumn>
          ))}
        {this.componentAtSectionLevel && (
          <GridColumn
            className={`nw-mt-component nw-componentatsectionlevel ${MasterComponent.marginClasses(
              isDesktop,
              sectionIndex,
              this.sortedData,
              parentData
            )}`}
          >
            <ComponentDecider.component componentData={this.sortedData} />
          </GridColumn>
        )}
      </GridRow>
    );
  }
}

MasterComponent.wrappedComponent.defaultProps = {
  parentData: [],
  CommonStore: {
    isDesktop: false
  }
};
MasterComponent.wrappedComponent.propTypes = {
  index: PropTypes.number.isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool.isRequired,
    brandJourneyId: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool
  }),
  parentData: PropTypes.shape([]),
  data: PropTypes.shape({}).isRequired,
  BrandStore: PropTypes.shape({
    templateClass: PropTypes.string.isRequired
  }).isRequired,
  level: PropTypes.number.isRequired
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MasterComponent.wrappedComponent,
  component: MasterComponent
};

export default ProjectComponent;
