import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { GridContainer, Loader, Utils } from "arv-reactcomponents";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FULL_WIDTH_MASTER_TEMPLATES } from "npmlinks-constants";
import lodashGet from "lodash.get";
import { MasterComponent, ComponentPool } from "../../component/MasterTemplate";
import {
  setPageType,
  isIframe,
  postIframeMessage,
  deduceValuesFromPipedData,
  setIsLandscape,
} from "../../utils";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";

@inject("BrandStore", "CommonStore", "GetTypeStore")
@observer
class BrandPage extends Component {
  static post() {
    if (isIframe) {
      setTimeout(() => {
        const body = document.getElementsByClassName("nw-app")[0];
        const height = (body && body.offsetHeight) || 0;
        postIframeMessage({ type: "brandIframeHeight", data: { height } });
      }, 3000);
    }
  }
  static getTemplateClass(isDesktop, props) {
    const { templateType, templateClass } = props.BrandStore;
    const { brandJourneyId } = props.CommonStore;
    const type =
      FULL_WIDTH_MASTER_TEMPLATES.includes(templateType) || !isDesktop
        ? "nw-fullwidthtemplate"
        : "nw-mastertemplatelayout";
    return `nw-mt-${brandJourneyId} ${type} ${templateClass}`;
  }
  constructor(props) {
    super(props);

    const { isDesktop } = this.props.CommonStore;
    this.state = {
      templateClass: BrandPage.getTemplateClass(isDesktop, props),
      loaded: false,
    };
    this.init = this.init.bind(this);
    this.listen = this.listen.bind(this);
  }
  listen() {
    window.addEventListener(
      "message",
      event => {
        if (event && event.data && event.data.type === "accessToken") {
          const accessToken = event.data.data;
          this.props.BrandStore.setToken(accessToken);
          this.init();
        }
      },
      false,
    );
  }
  init() {
    setIsLandscape();
    const { data } = this.props.BrandStore;
    const { isDesktop } = this.props.CommonStore;
    if (!data) {
      this.props.BrandStore.getBrandPageData(
        window.location.hostname,
        isDesktop,
      );
    }
  }
  componentDidCatch(error, errorInfo) {
    console.error(`error in brandpage ${error} ${errorInfo} ${this}`);
  }
  componentDidMount() {
    Utils.scrollTo(window, 0, 0, 300);
    const { templateType } = this.props.BrandStore;
    setPageType(templateType);
    Analytics.pageView(window.pageType);
    window.google_tag_params = {
      ecomm_pagetype: Event.GTMEvent.ECOMM_HOME,
    };
    if (isIframe) {
      this.listen();
    } else {
      this.init();
    }
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ loaded: true });
  }
  get hasBreadcrumbs() {
    return lodashGet(this, "props.GetTypeStore.data.breadCrumbs.length", 0) > 0
      ? "has-bc"
      : "";
  }
  get validatedSections() {
    try {
      const { data } = toJS(this.props.BrandStore);
      return (data && data.filter(item => item.type)) || [];
    } catch (error) {
      return [];
    }
  }
  render() {
    const { data } = toJS(this.props.BrandStore);
    const {
      isShareable,
      isFavoritable,
      shareImage,
      urlPrefix,
    } = this.props.BrandStore;
    const { templateClass } = this.state;
    const { title, description } = deduceValuesFromPipedData(
      (window && window.getType && window.getType.type) || "",
    );
    if (data) {
      BrandPage.post();
    }
    if (this.props.CommonStore.isIframe && !this.state.loaded) {
      return null;
    }
    if (!data)
      return (
        <div>
          <div className="nw-mastertemplatepage-loading">
            <Loader />
          </div>
        </div>
      );
    return (
      <GridContainer
        className={`nw-mastertemplatepage ${templateClass} ${
          this.hasBreadcrumbs
        }`}
      >
        {(this.state.loaded && (
          <ComponentPool.FavAndShare.default.component
            view="absolute"
            title={title || document.title}
            description={description}
            url={window.location.href}
            id={window.location.href}
            type="blogs"
            image={`${urlPrefix}${shareImage}`}
            shareable={isShareable}
            favoritable={isFavoritable}
            show={isFavoritable || isShareable}
            category="blogs"
          />
        )) ||
          null}
        {this.validatedSections.map((component, index) => (
          <MasterComponent.component
            data={component}
            key={component.type}
            level={0}
            parentData={this.validatedSections}
            index={index}
          />
        ))}
      </GridContainer>
    );
  }
}

BrandPage.wrappedComponent.propTypes = {
  BrandStore: PropTypes.shape({
    urlPrefix: PropTypes.string.isRequired,
    shareImage: PropTypes.string.isRequired,
    isShareable: PropTypes.bool.isRequired,
    isFavoritable: PropTypes.bool.isRequired,
    setToken: PropTypes.func.isRequired,
    templateClass: PropTypes.string.isRequired,
    templateType: PropTypes.string.isRequired,
    getBrandPageData: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandJourneyId: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isIframe: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

export const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandPage.wrappedComponent,
  component: BrandPage,
};

export default ProjectComponent.component;
