import React, { Component } from "react";
import PropTypes from "prop-types";

import TitleDescriptionHorizontal from "../TitleDescriptionHorizontal";
import ProductCarouselComponent from "../ProductCarouselComponent";

class CmtCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideComponent: false,
    };
  }
  componentDidMount() {
    console.log("ComponentPool->CMTCarousel", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error CMTCarousel ${error} ${errorInfo} ${this}`,
    );
  }
  /*
  for newArrivals and onSale we will get data in object structure and based on visible field inside it we will render component
  for blocks we will get data as an Array
 */

  // BLocks element should have collection Id otherwise if we render Product carousal Component then listing api call will give bad request
  shouldComponentBeShown(titleDesriptionData) {
    return (
      titleDesriptionData && titleDesriptionData.id && !this.state.hideComponent
    );
  }
  hideComponent() {
    this.setState(prevState => {
      const newState = prevState;
      newState.hideComponent = true;
      return newState;
    });
  }

  render() {
    const { data } = this.props;

    const modifiedDataForCarousel = {
      width: "12",
      type: "productCarousal",
      storyType: {
        type: data.type || "collection",
        pcmData: data.id,
      },
      dPos: 1,
      params: data.params,
      identity: data.identity || null,
    };

    // TODO :: pass one hide component Function so that if data is not there for caraousel (list) was not found it will remove this component
    return this.shouldComponentBeShown(data) ? (
      <div>
        <TitleDescriptionHorizontal.component data={data} />
        <ProductCarouselComponent.component
          data={modifiedDataForCarousel}
          hideComponent={() => this.hideComponent()}
        />
      </div>
    ) : null;
  }
}
CmtCarousel.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.shape({}),
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: CmtCarousel,
  component: CmtCarousel,
};

export default ProjectComponent;
